<!-- navigation-bar -->
<mat-toolbar
  class="flex-layout-align-center-center"
  id="toolbar"
  color="#fff"
  ngSkipHydration
>
  <div class="flex-1600px flex-100-lt-lg flex-layout-row">
    <!-- PRIVATE & PUBLIC -->
    <!-- fxHide="true" fxShow.lt-md="true" -->
    <div class="sidenav-toggler">
      <button
        mat-button
        name="sienav-menu"
        (click)="sidenav.toggle()"
        class="menu-button"
        aria-label="menu"
      >
        <mat-icon>menu</mat-icon>
      </button>
    </div>
    <!-- PRIVATE & PUBLIC -->
    <a routerLink="/{{ translate.currentLang }}" class="logo"
      ><img
        [src]="'../../../assets/img/li-x-logo' + logoSuffix + '.svg'"
        alt="{{ 'img_alt_text.lix_logo' | translate }}"
    /></a>
    <div class="header-menus" *ngIf="currentUser">
      <!-- PRIVATE -->
      <a id="headerLicencesID" mat-button (click)="goto('licences')"
        >{{ 'header.my_licences' | translate }}</a
      >
      <!-- PRIVATE -->
      <a id="headerProjectsID" mat-button (click)="goto('projects')"
        >{{ 'header.projects' | translate }}</a
      >
      <!-- PRIVATE & RESELLER OR VERIFIED-->
      <button
        id="clientsId"
        *ngIf="currentUser.isReseller && currentUser.verified"
        mat-button
        [matMenuTriggerFor]="resellerMenu"
        class="dropdown-btn"
      >
        <span fxLayout="row" fxLayoutAlign="center center">
          <span>{{ 'header.reseller.title' | translate }}</span>
          <mat-icon>arrow_drop_down</mat-icon>
        </span>
      </button>
      <mat-menu #resellerMenu="matMenu">
        <button
          id="headerTransferId"
          mat-menu-item
          (click)="goto('reseller/transfer')"
        >
          <a>{{ 'header.title.transfers' | translate }}</a>
        </button>
        <button
          id="headerCustomersId"
          mat-menu-item
          (click)="goto('reseller/customers')"
        >
          <a>{{ 'header.reseller.sub_menu.item2' | translate }}</a>
        </button>
      </mat-menu>
      <!-- PRIVATE -->
      <a mat-button name="purchase-header" (click)="goto('purchases')"
        >{{ 'header.title.purchases' | translate }}</a
      >
    </div>

    <!-- PUBLIC ONLY -->
    <!-- <div fxShow="true" fxHide.lt-md="true"> -->
    <div class="header-menus" fxShow="true" fxHide.lt-md="true">
      <span *ngIf="!currentUser">
        <span *ngFor="let item of topMenu; let i = index">
          <!-- start of hidden span for google bots to get product links -->
          <span class="hidden-span" *ngIf="item.items">
            <ng-container *ngFor="let mainItem of item.items">
              <ng-container *ngIf="mainItem.items; else singleSubItem">
                <a
                  *ngFor="let subItem of mainItem.items"
                  [routerLink]="subItem.link"
                  [queryParams]="subItem.params"
                >
                  {{ subItem.title }}
                </a>
              </ng-container>
              <ng-template #singleSubItem>
                <a [routerLink]="mainItem.link" [queryParams]="mainItem.params">
                  {{ mainItem.title }}
                </a>
              </ng-template>
            </ng-container>
          </span>
          <!-- end of hidden span for google bots to get product links -->
          <span *ngIf="item.items; else singleItem">
            <button
              mat-button
              [matMenuTriggerFor]="main_menu"
              class="dropdown-btn"
            >
              <span fxLayout="row" fxLayoutAlign="center center">
                <span>{{ item.title }}</span>
                <mat-icon>arrow_drop_down</mat-icon>
              </span>
            </button>
            <mat-menu #main_menu="matMenu">
              <ng-container *ngFor="let mainItem of item.items">
                <ng-container *ngIf="mainItem.items; else singleSubItem">
                  <button mat-menu-item [matMenuTriggerFor]="sub_menu">
                    {{ mainItem.title }}
                  </button>
                  <mat-menu #sub_menu="matMenu">
                    <a
                      *ngFor="let subItem of mainItem.items"
                      mat-menu-item
                      [routerLink]="subItem.link"
                      [queryParams]="subItem.params"
                      >{{ subItem.title }}</a
                    >
                  </mat-menu>
                </ng-container>
                <ng-template #singleSubItem>
                  <a
                    mat-menu-item
                    [routerLink]="mainItem.link"
                    [queryParams]="mainItem.params"
                    >{{ mainItem.title }}</a
                  >
                </ng-template>
              </ng-container>
            </mat-menu>
          </span>
          <ng-template #singleItem>
            <a mat-button [routerLink]="item.link" [queryParams]="item.params"
              >{{ item.title }}</a
            >
          </ng-template>
        </span>
      </span>
    </div>

    <span class="fill-remaining-space"></span>

    <div fxShow="true" fxHide.lt-md="true">
      <!-- PUBLIC & PRIVATE -->
      <!--<button
        #contact
        mat-button
        class="contact-button"
        (click)="openPopup($event, 'contact', contact)"
      >
        {{ 'header.contact_us' | translate }}
      </button>-->

      <button
        *ngIf="!currentUser"
        mat-button
        color="primary"
        routerLink="/{{ translate.currentLang }}/{{ 'header.link.inquiry' | translate }}"
      >
        {{ 'header.button.inquiry_now' | translate }}
      </button>

      <span fxFlex="30px"></span>
      <!-- PUBLIC & PRIVATE -->
      <button
        id="localeCurrencyID"
        #locale
        mat-button
        (click)="openPopup($event, 'locale', locale)"
      >
        <mat-icon>language</mat-icon> {{ 'header.lang' | translate }} - {{
        currencyService.currentCurrency }}
      </button>
      <!-- start of hidden span for google bots to get all language links -->
      <span class="hidden-span">
        <a *ngFor="let url of seoPageUrl" [routerLink]="url"> {{ url }} </a>
      </span>
      <!-- end of hidden span for google bots to get all language links -->
      <!-- PUBLIC -->
      <a
        id="loginBtnID"
        *ngIf="!currentUser"
        mat-button
        color="primary"
        routerLink="login"
        routerLinkActive="active"
        (click)="getCurrentSlug()"
        rel="nofollow"
        >{{ 'header.login' | translate }}</a
      >
      <!-- PUBLIC -->
      <button
        mat-raised-button
        class="search lix-raised-button outlined"
        (click)="search($event)"
      >
        {{ 'header.search' | translate }}
      </button>
    </div>
    <button
      mat-raised-button
      color="accent"
      routerLink="buy"
      class="buy-button"
    >
      {{ 'header.buy' | translate }}
    </button>
    <div fxShow="true" fxHide.gt-sm="true">
      <button mat-button (click)="search($event)" aria-label="search">
        <mat-icon>search</mat-icon>
      </button>
    </div>
    <a routerLink="cart" mat-button rel="nofollow" aria-label="cart">
      <div
        class="badge-quantity"
        [matBadge]="cartItems || ''"
        matBadgePosition="before"
      >
        <mat-icon>work_outline</mat-icon>
      </div>
    </a>

    <div class="vl header-menus" *ngIf="currentUser"></div>
    <div *ngIf="currentUser" class="header-menus">
      <button
        id="btnSettings"
        mat-button
        [matMenuTriggerFor]="menu"
        class="dropdown-btn"
      >
        <span fxLayout="row" fxLayoutAlign="center center">
          <span>{{ 'header.settings' | translate }}</span>
          <mat-icon> arrow_drop_down</mat-icon>
        </span>
      </button>
      <mat-menu #menu="matMenu" class="menu-items">
        <button mat-menu-item (click)="goto('settings/account')">
          {{ 'header.account' | translate }}
        </button>
        <button
          id="btnUsrMngmnt"
          *ngIf="currentUser && currentUser.permissions.indexOf('admin') !== -1"
          mat-menu-item
          (click)="goto('settings/users')"
        >
          {{ 'header.user_manage' | translate }}
        </button>
        <button mat-menu-item (click)="goto('settings/company')">
          {{ 'header.company_detail' | translate }}
        </button>
      </mat-menu>
      <button id="logoutId" mat-button (click)="logout()" class="red">
        {{ 'header.logout' | translate }}
      </button>
    </div>
  </div>
</mat-toolbar>

<!-- sidebar -->
<mat-sidenav-container fxFlexFill>
  <!-- fxHide.gt.sm="true" -->
  <mat-sidenav
    #sidenav
    [fixedInViewport]="true"
    [fixedTopGap]="90"
    fxLayout="column"
    mode="over"
    opened="false"
    id="sidebar-container"
  >
    <mat-sidenav-content fxFlexFill>
      <div fxLayout="column">
        <mat-list>
          <!-- (click)="sidenav.toggle(); goto('locale')" -->
          <div
            class="sidenav-contact-locale"
            fxHide.md=""
            fxLayout="row"
            cdkFocusInitial
          >
            <span class="span-2" fxLayoutAlign="center center">
              <div class="right-pad">
                <button mat-button [matMenuTriggerFor]="locale_menu">
                  <img
                    class="locale-currency"
                    src="/assets/img/icon_locale.svg"
                  />
                  {{ localeCurrencyMenu[0].title }}
                  <img
                    class="locale-currency-switcher"
                    src="/assets/img/icon_dropdown.svg"
                  />
                </button>
              </div>
              <button mat-button [matMenuTriggerFor]="currency_menu">
                <img
                  class="locale-currency"
                  src="/assets/img/icon_currency.svg"
                />
                {{ localeCurrencyMenu[1].title }}
                <img
                  class="locale-currency-switcher"
                  src="/assets/img/icon_dropdown.svg"
                />
              </button>
              <mat-menu #locale_menu="matMenu">
                <ng-container
                  *ngFor="let localeCurrency of localeCurrencyMenu[0].items"
                >
                  <button
                    mat-menu-item
                    (click)="useCurrencyLanguage(localeCurrency)"
                  >
                    {{ localeCurrency.title }}
                  </button>
                </ng-container>
              </mat-menu>
              <mat-menu #currency_menu="matMenu">
                <ng-container
                  *ngFor="let localeCurrency of localeCurrencyMenu[1].items"
                >
                  <button
                    mat-menu-item
                    (click)="useCurrencyLanguage(localeCurrency)"
                  >
                    {{ localeCurrency.title }}
                  </button>
                </ng-container>
              </mat-menu>
              <!-- <mat-icon class="lang-icon">language</mat-icon>
              {{ 'header.lang' | translate }} - {{ currencyService.currentCurrency }} -->
            </span>
            <!-- <span fxFlex=".8 .8 auto"></span>
            <p>{{ 'header.change_language' | translate }}</p>
            <p class="arrow-icon">
              <mat-icon>arrow_right_alt</mat-icon>
            </p> -->
          </div>
          <mat-divider></mat-divider>
          <a
            class="contact-item mobile-view-a"
            routerLink="login"
            (click)="sidenav.toggle()"
            *ngIf="!currentUser"
            (click)="getCurrentSlug()"
            rel="nofollow"
          >
            <span class="span-3">{{ 'header.login' | translate }}</span>
          </a>
          <a
            class="contact-item mobile-view-a"
            routerLink="register"
            (click)="sidenav.toggle()"
            *ngIf="!currentUser"
            rel="nofollow"
          >
            <span class="span-3"
              >{{ 'header.create_account' | translate }}</span
            >
          </a>
          <button
            class="contact-item"
            (click)="sidenav.toggle(); logout()"
            *ngIf="currentUser"
          >
            <span class="span-4">{{ 'header.logout' | translate }}</span>
          </button>
          <mat-divider></mat-divider>
          <div *ngIf="!currentUser && mobileMenu">
            <a
              (click)="sidenav.toggle()"
              routerLink="{{item.url}}"
              *ngFor="let item of mobileMenu"
            >
              <span class="span-3">{{ item.caption }}</span>
            </a>
          </div>

          <a *ngIf="currentUser" (click)="sidenav.toggle(); goto('licences')">
            <span class="span-3">{{ 'header.my_licences' | translate }}</span>
          </a>
          <a *ngIf="currentUser" (click)="sidenav.toggle(); goto('projects')">
            <span class="span-3">{{ 'header.projects' | translate }}</span>
          </a>
          <mat-expansion-panel
            *ngIf="
              currentUser && currentUser.isReseller && currentUser.verified
            "
          >
            <mat-expansion-panel-header>
              <mat-panel-title name="clients-sidenav">
                {{ 'header.reseller.title' | translate }}
              </mat-panel-title>
              <mat-panel-description> </mat-panel-description>
            </mat-expansion-panel-header>
            <div fxLayout="column">
              <button
                mat-button
                name="transfer-sidenav"
                (click)="sidenav.toggle(); goto('reseller/transfer')"
              >
                <a>{{ 'header.title.transfers' | translate }}</a>
              </button>
              <button
                mat-button
                (click)="sidenav.toggle(); goto('reseller/customers')"
              >
                <a>{{ 'header.reseller.sub_menu.item2' | translate }}</a>
              </button>
            </div>
          </mat-expansion-panel>
          <a *ngIf="currentUser" (click)="sidenav.toggle(); goto('purchases')">
            <span name="purchases-sidenav" class="span-3"
              >{{ 'header.title.purchases' | translate }}</span
            >
          </a>
          <!-- <a (click)="sidenav.toggle(); goto('sell')">
            <h3>{{'header.title.sell' | translate}}</h3>
          </a> -->
          <mat-expansion-panel *ngIf="currentUser">
            <mat-expansion-panel-header>
              <mat-panel-title>
                {{ 'header.settings' | translate }}
              </mat-panel-title>
              <mat-panel-description> </mat-panel-description>
            </mat-expansion-panel-header>
            <div fxLayout="column">
              <button
                mat-button
                (click)="sidenav.toggle(); goto('settings/account')"
              >
                <a>{{ 'header.account' | translate }}</a>
              </button>
              <button
                mat-button
                (click)="sidenav.toggle(); goto('settings/users')"
              >
                <a>{{ 'header.user_manage' | translate }}</a>
              </button>
              <button
                mat-button
                (click)="sidenav.toggle(); goto('settings/company')"
              >
                <a>{{ 'header.company_detail' | translate }}</a>
              </button>
            </div>
          </mat-expansion-panel>
          <mat-divider fxHide.md=""></mat-divider>
          <app-contact-menu fxHide.md=""></app-contact-menu>
        </mat-list>
      </div>
    </mat-sidenav-content>
  </mat-sidenav>
  <mat-sidenav-content fxFlexFill>
    <div class="content">
      <router-outlet></router-outlet>
    </div>
    <div *ngIf="!currentUser" class="footer" fxLayout="column">
      <span fxFlex="1 1 auto"></span>
      <app-footer></app-footer>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>

<app-popup [currentUser]="currentUser"></app-popup>
