import { Injectable } from '@angular/core'
import {
  HttpRequest,
  HttpResponse,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HTTP_INTERCEPTORS,
} from '@angular/common/http'
import { Observable, of, throwError } from 'rxjs'
import { delay, mergeMap, materialize, dematerialize } from 'rxjs/operators'

const products = [
  {
    name: 'Office Professional Plus 2016',
    id: 1669,
    vendor: 'Microsoft',
    h: 'Windows',
    vendor_price: 472,
    price: 179,
    available: 30,
    class: 'tools',
  },
  {
    name: 'Office Professional Plus 2013',
    id: 1670,
    vendor: 'Microsoft',
    h: 'Windows',
    vendor_price: 472,
    price: 179,
    available: 30,
    class: 'tools',
  },
  {
    name: 'Arch Linux',
    id: 1,
    vendor: 'Community',
    h: 'Windows',
    vendor_price: 0,
    discount: 10,
    price: 1,
    available: 30,
    class: 'os',
    // eslint-disable-next-line max-len
    description:
      'Arch Linux ist eine AMD64-optimierte Linux-Distribution mit Rolling Releases, dessen Entwicklerteam dem KISS-Prinzip folgt. Zugunsten der Einfachheit wird auf grafische Installations- und Konfigurationshilfen verzichtet.',
  },
  {
    name: 'Windows NT',
    id: 1671,
    vendor: 'Microsoft',
    h: 'Windows',
    vendor_price: 472,
    price: 9,
    available: 30,
    class: 'os',
  },
  {
    name: 'Office Standard 2016',
    id: 1672,
    vendor: 'Microsoft',
    h: 'Windows',
    vendor_price: 472,
    price: 179,
    available: 30,
    class: 'tools',
  },
]

@Injectable()
export class MarketServiceFakeInterceptor implements HttpInterceptor {
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return of(null)
      .pipe(
        mergeMap(() => {
          // if (/\/market\/product\/\d+$/i.test(request.url) && request.method === 'GET') {
          //     const id = parseInt(request.url.match(/\/market\/product\/(\d+)$/i)[1], 10)
          //     for (const product of products) {
          //         if (product.id === id) {
          //             return ok(product)
          //         }
          //     }
          //     return error('product not found')
          // }
          // if (/\/market\/suggest\/.+$/i.test(request.url) && request.method === 'GET') {
          //     const query = request.url.match(/\/market\/suggest\/(.+)$/i)[1]
          //     const suggestions = [query + ' office', query + ' 2016', query + ' 2013', query + ' professional']
          //     return ok(suggestions)
          // }
          // if (request.url.endsWith('/market/products') && request.method === 'GET') {
          //     return ok(products)
          // }
          if (
            /\/market\/recommendations\/\d+$/i.test(request.url) &&
            request.method === 'GET'
          ) {
            const random = Math.floor(Math.random() * products.length)
            const count = 3
            const recommendations = []
            for (let i = 0; i < count; i++) {
              recommendations.push(
                products[(random + i) % (products.length - 1)]
              )
            }
            return ok(recommendations)
          }
          // if (/\/market\/search\?q=.+$/i.test(request.url) && request.method === 'GET') {
          //     return ok(products)
          // }
          // pass through any requests not handled above
          return next.handle(request)
        })
      )
      .pipe(materialize())
      .pipe(delay(500))
      .pipe(dematerialize())

    // private helper functions

    function ok(body) {
      return of(new HttpResponse({ status: 200, body }))
    }

    function unauthorised() {
      return throwError({ status: 401, error: { message: 'Unauthorised' } })
    }

    function error(message) {
      return throwError({ status: 400, error: { message } })
    }
  }
}

export const MarketServiceFakeProvider = {
  // use fake backend in place of Http service for backend-less development
  provide: HTTP_INTERCEPTORS,
  useClass: MarketServiceFakeInterceptor,
  multi: true,
}
