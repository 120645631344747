import { ActivatedRouteSnapshot } from '@angular/router'
import { Injectable, Inject, PLATFORM_ID } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { SeoService } from './services/seo.service'
import { UtilsService } from './services/utils.service'
import { environment } from '../environments/environment'

@Injectable()
export class SeoResolver {
  constructor(
    private translate: TranslateService,
    private seoService: SeoService,
    private utils: UtilsService,
    @Inject(PLATFORM_ID) private _platformId: Object
  ) {}

  async resolve(route: ActivatedRouteSnapshot): Promise<any> {
    this.seoService.setMetaTagsFromRouteData(route)
    this.hrefLinkResolver(route)
    if (!route.data.canonical) {
      this.seoService.createCanonicalTag(
        environment.origin + '/' + this.utils.getFullResolvedUrl(route)
      )
    } else {
      this.seoService.createCanonicalTag(route.data.canonical)
    }

    return this.utils.getFullResolvedUrl(route)
  }

  hrefLinkResolver(route: ActivatedRouteSnapshot) {
    const seoPageUrl: string[] = []
    const hasQueryParams = Object.keys(route.queryParams).length > 0
    this.seoService.removeHrefLinkTags()

    // URLs with query params will be considered duplicate pages,
    // their canonical tag will point to the original page and therefore
    // the hreflang tags shouldn't exist. This method applies only to
    // angular internal pages.
    if (!hasQueryParams) {
      const languageList = this.translate.getLangs()
      const defaultLanguage = this.translate.getDefaultLang()

      const path = this.getResolvedUrl(route)

      this.seoService.createHrefLinkTag(defaultLanguage, path, true)
      languageList.forEach((lang) => {
        this.seoService.createHrefLinkTag(lang, path)
        seoPageUrl.push(`/${lang}/${path}`)
      })
      this.seoService.setlangURLs(seoPageUrl)
    }
  }

  private getResolvedUrl(route: ActivatedRouteSnapshot): string {
    return route.pathFromRoot
      .filter((e, index) => index > 1 && e.url && e.url.length > 0)
      .map((v) => v.url.map((segment) => segment.toString()).join('/'))
      .join('/')
  }
}
