import { Injectable, PLATFORM_ID, Inject } from '@angular/core'
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http'
import { Observable, throwError, Subject } from 'rxjs'
import { catchError, switchMap } from 'rxjs/operators'
import { Router } from '@angular/router'
import { TranslateService } from '@ngx-translate/core'
import { SnackbarService } from '../services/snackbar.service'
import { isPlatformBrowser } from '@angular/common'
import { AuthenticationService } from '../services/authentication.service'

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private router: Router,
    private translate: TranslateService,
    private snackbarService: SnackbarService,
    private authService: AuthenticationService
  ) {}

  private refreshTokenSubject: Subject<any> = new Subject<any>()

  private tokenExpired() {
    this.refreshTokenSubject.subscribe({
      complete: () => {
        this.refreshTokenSubject = new Subject<any>()
      },
    })
    if (this.refreshTokenSubject.observers.length === 1) {
      this.authService.refreshToken().subscribe(this.refreshTokenSubject)
    }
    return this.refreshTokenSubject
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (request.url.includes('logout') || request.url.includes('refresh')) {
      return next.handle(request).pipe(
        catchError((err) => {
          this.authService.logout()
          this.refreshTokenSubject = new Subject<any>()
          this.router.navigateByUrl(this.translate.currentLang + '/login', {
            replaceUrl: true,
          })
          const error = err.error || err.error.message || err.statusText
          if (error && typeof error === 'object') {
            error.status = err.status
          }
          return throwError(error)
        })
      )
    } else {
      return next.handle(request).pipe(
        catchError((err, caught) => {
          if (err instanceof HttpErrorResponse) {
            if (err.status && err.status === 401) {
              return this.tokenExpired().pipe(
                switchMap(() => {
                  return next.handle(this.updateHeader(request))
                })
              )
            } else if (err.status && err.status === 404) {
              if (isPlatformBrowser(this.platformId)) {
                this.snackbarService.showSnackbar(
                  'error.server_error',
                  'error.button.close',
                  'error-snack'
                )
              }
            } else if (err.status && err.status === 500) {
              // Server error
              this.snackbarService.showSnackbar(
                'error.server_error',
                'error.button.close',
                'error-snack'
              )
            }
            const error = err.error || err.error.message || err.statusText
            if (error && typeof error === 'object') {
              error.status = err.status
            }
            return throwError(error)
          }
          return caught
        })
      )
    }
  }

  updateHeader(req) {
    const authToken = this.authService.currentUserValue
    req = req.clone({
      headers: req.headers.set('Authorization', `Bearer ${authToken}`),
    })
    return req
  }
}
