import { Injectable } from '@angular/core'
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
} from '@angular/common/http'
import { Observable, throwError } from 'rxjs'
import { catchError, tap, map } from 'rxjs/operators'
import { LoaderService } from '../services/loader.service'

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  constructor(private loader: LoaderService) {}
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.loader.showLoader()
    return next.handle(request).pipe(
      map((r) => {
        if (r instanceof HttpResponse) {
          if (r.body && r.ok) {
            this.loader.hideLoader()
          }
        }
        return r
      }),
      catchError((error) => {
        this.loader.hideLoader()
        return throwError(error)
      })
    )
  }
}
