import { Component, OnInit, OnDestroy } from '@angular/core'
import { UntypedFormControl } from '@angular/forms'
import { Observable, Subject, Subscription } from 'rxjs'
import { MatDialogRef } from '@angular/material/dialog'
import { Router } from '@angular/router'
import { MarketService } from '../../services/market.service'
import { TranslateService } from '@ngx-translate/core'
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators'

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements OnDestroy {
  searchForm = new UntypedFormControl()

  filteredOptions: Observable<string[]>
  term$ = new Subject<string>()

  private searchSubscription: Subscription

  constructor(
    public dialogRef: MatDialogRef<SearchComponent>,
    private marketService: MarketService,
    private router: Router,
    private translate: TranslateService
  ) {
    // debounceTime waits for the provided time in ms until it allows for new changes
    // distinctUntilChanges will not allow the same input to pass through two times in a row
    // switchMap takes the latest observable from the chain so you don't get multiple results at once
    this.searchSubscription = this.term$
      .pipe(
        debounceTime(1000),
        distinctUntilChanged(),
        switchMap((term) => {
          if (term && term.length > 2) {
            this.filteredOptions = this.marketService.suggest(term)
            return term
          } else {
            return ' '
          }
        })
      )
      .subscribe()
  }

  public close() {
    this.dialogRef.close()
  }

  searchKeyword(event, keyword) {
    if (event.keyCode === 13) {
      this.router.navigate([this.translate.currentLang, 'buy'], {
        queryParams: { q: keyword },
      })
      this.close()
    }
  }

  onClick(keyword) {
    this.router.navigate([this.translate.currentLang, 'buy'], {
      queryParams: { q: keyword },
    })
    this.close()
  }

  ngOnDestroy() {
    // remember to unsubscribe on destroy
    if (this.searchSubscription) {
      this.searchSubscription.unsubscribe()
      this.searchSubscription = null
    }
  }
}
