import {
  Injectable,
  EventEmitter,
  Inject,
  PLATFORM_ID,
  isDevMode,
} from '@angular/core'
import { environment } from '../../environments/environment'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import { isPlatformServer } from '@angular/common'

export interface IRatesConfig {
  currencyRates: IRates
  date: string
}

export interface IRates {
  EUR: number
  GBP: number
  CHF: number
}

@Injectable({
  providedIn: 'root',
})
export class CurrencyService {
  currentCurrency = 'EUR'
  date: string
  rates: IRates
  onCurrencyChange = new EventEmitter<string>()

  constructor(
    private http: HttpClient,
    @Inject(PLATFORM_ID) private _platformId: Object
  ) {
    // try load from ls
    if (!isPlatformServer(this._platformId)) {
      this.currentCurrency = localStorage.getItem('currency') || 'EUR'
      const record = JSON.parse(localStorage.getItem('rates'))
      // check if date is today
      if (
        record === null ||
        record.date !== new Date().toISOString().substr(0, 10)
      ) {
        this.updateRates(true)
      } else {
        this.rates = record.currencyRates
        this.date = record.date
      }
    } else {
      this.updateRates(false)
    }
  }

  async updateRates(storeResult: boolean = true) {
    try {
      const result = await this.getAllRates().toPromise()
      this.rates = result.currencyRates
      this.date = result.date
      if (storeResult) {
        if (!isPlatformServer(this._platformId)) {
          localStorage.setItem('rates', JSON.stringify(result))
        }
      }
    } catch (err) {
      if (isDevMode) {
        // eslint-disable-next-line no-console
        console.error(err)
      }
    }
  }

  getRate() {
    return this.rates ? this.rates[this.currentCurrency] : undefined
  }

  getAllRates(): Observable<IRatesConfig> {
    return this.http.get<IRatesConfig>(
      `${environment.server_url}/payment/exchange-rates`,
      {
        transferCache: false,
      }
    )
  }

  setCurrency(currency: string) {
    if (this.rates[currency]) {
      this.currentCurrency = currency
      this.onCurrencyChange.emit(currency)
      if (!isPlatformServer(this._platformId)) {
        localStorage.setItem('currency', currency)
      }
    } else {
      throw Error(`Currency unknown: ${currency}`)
    }
  }
}
