import { Injectable, EventEmitter } from '@angular/core'
import { Subject } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  loadingEvent: Subject<boolean> = new Subject<boolean>()

  level = 0

  constructor() {}

  showLoader() {
    this.loadingEvent.next(++this.level > 0)
  }

  hideLoader() {
    this.loadingEvent.next(--this.level > 0)
  }
}
