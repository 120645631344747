import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { TranslateModule } from '@ngx-translate/core'
import { FlexLayoutModule } from '@angular/flex-layout'
import { PageLoaderComponent } from './page-loader.component'
import { PipeModule } from '../../../pipes/pipe.module'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'

@NgModule({
  declarations: [PageLoaderComponent],
  imports: [
    CommonModule,
    PipeModule,
    FlexLayoutModule,
    TranslateModule.forChild(),
    MatProgressSpinnerModule,
  ],
  exports: [PageLoaderComponent, MatProgressSpinnerModule],
})
export class PageLoaderModule {}
