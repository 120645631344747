import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { JsonldComponent } from './jsonld.component'

@NgModule({
  imports: [CommonModule],
  declarations: [JsonldComponent],
  exports: [JsonldComponent],
})
export class JsonldModule {}
