import { ErrorHandler, Injectable } from '@angular/core'

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  handleError(error: any): void {
    const chunkFailedMessage = /Loading chunk [\d]+ failed/

    if (chunkFailedMessage.test(error.message)) {
      const totalRegisteredChunkFailures = this.registerChunkFailure()
      if (
        totalRegisteredChunkFailures > 0 &&
        totalRegisteredChunkFailures <= 5
      ) {
        window.location.reload()
      }
    }
  }

  private registerChunkFailure() {
    try {
      const currentFailures = sessionStorage.getItem('CHUNK_FAILURES')

      let newFailureCount: number

      if (currentFailures === null) {
        newFailureCount = 1
      } else {
        newFailureCount = parseInt(currentFailures, 10) + 1
      }

      sessionStorage.setItem('CHUNK_FAILURES', newFailureCount.toString())

      return newFailureCount
    } catch (error) {
      console.error('Error registering chunk failure:', error)
      return 0
    }
  }
}
