import {
  Component,
  HostBinding,
  HostListener,
  Input,
  ViewChild,
  ElementRef,
} from '@angular/core'
import { LocaleMenuComponent } from '../locale-menu/locale-menu.component'
import { ContactMenuComponent } from '../contact-menu/contact-menu.component'
import { User } from '../../models/user.model'

@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss'],
})
export class PopupComponent {
  @ViewChild(LocaleMenuComponent)
  langMenu: LocaleMenuComponent
  @ViewChild(ContactMenuComponent)
  contactMenu: ContactMenuComponent

  @HostBinding('style.top') y = '0px'
  @HostBinding('style.left') x = '0px'
  @HostBinding('style.visibility') visibility = 'hidden'

  @Input() public currentUser: User

  menuType: string
  xStartOfPopUp: number

  constructor() {}

  open(e: MouseEvent, menuType: string, element: ElementRef) {
    const top = element.nativeElement.offsetTop
    const left = element.nativeElement.offsetLeft
    const width = element.nativeElement.offsetWidth
    const height = element.nativeElement.offsetHeight
    const extraTopMargin = 12

    this.menuType = menuType

    const _x = left + width / 2

    if (menuType === 'locale') {
      this.x = `${_x - 155}px`
    } else {
      this.x = `${_x - 91}px`
    }

    this.y = `${top + height + extraTopMargin}px`

    this.visibility = 'visible'

    e.stopPropagation()
  }

  close() {
    this.visibility = 'hidden'
  }

  @HostListener('document:click')
  public onDocumentClick() {
    if (this.visibility === 'visible') {
      this.close()
    }
  }
  @HostListener('window:resize')
  public onWindowResize() {
    if (this.visibility === 'visible') {
      this.close()
    }
  }
}
