import { Component, OnInit, OnDestroy } from '@angular/core'
import {
  PrismicService,
  Menu,
  ContentContactInfo,
} from '../../../services/prismic.service'
import { TranslateService } from '@ngx-translate/core'
import { Subscription } from 'rxjs'

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit, OnDestroy {
  menus: Array<Menu> = []
  subscriptions: Subscription

  constructor(
    private prismic: PrismicService,
    private translate: TranslateService
  ) {}

  loadMenu(lang?: string) {
    this.prismic.getFooter(lang).then((menus) => {
      this.menus = menus
    })
  }

  ngOnInit() {
    this.subscriptions = new Subscription()
    this.subscriptions.add(
      this.translate.onLangChange.subscribe(() => {
        this.loadMenu()
      })
    )
    this.loadMenu()
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe()
  }
}
