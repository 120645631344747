import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { environment } from '../../environments/environment'
import {
  ICompanyAddress,
  ICompanyBilling,
  ICompanyContact,
  IUpdateUserData,
  ICreateUser,
  IUser,
  IBilling,
} from '../models/company.model'
import { Observable } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class CompanyService {
  constructor(private http: HttpClient) {}

  getAddress(): Observable<ICompanyAddress> {
    return this.http.get<ICompanyAddress>(
      `${environment.server_url}/company/address`
    )
  }

  updateAddress(addressData: any) {
    return this.http.put<ICompanyAddress>(
      `${environment.server_url}/company/address`,
      addressData
    )
  }

  getBilling(): Observable<IBilling> {
    return this.http.get<IBilling>(
      `${environment.server_url}/company/billing-information`
    )
  }

  updateBilling(billingData: ICompanyBilling) {
    return this.http.put<ICompanyBilling>(
      `${environment.server_url}/company/billing-information`,
      billingData
    )
  }

  getContact(): Observable<ICompanyContact> {
    return this.http.get<ICompanyContact>(
      `${environment.server_url}/company/contact-details`
    )
  }

  updateContact(contactData: ICompanyContact) {
    return this.http.put<ICompanyContact>(
      `${environment.server_url}/company/contact-details`,
      contactData
    )
  }

  getUsers(): Observable<IUser[]> {
    return this.http.get<IUser[]>(`${environment.server_url}/company/users`)
  }

  updateUserInfo(userId: number, userData: IUpdateUserData) {
    return this.http.put<any>(
      `${environment.server_url}/company/user/${userId}/info`,
      userData
    )
  }

  updateUserPermission(userId: number, userData: any) {
    return this.http.put<any>(
      `${environment.server_url}/company/user/${userId}/permission`,
      userData
    )
  }

  createUser(userData: ICreateUser) {
    return this.http.post<any>(
      `${environment.server_url}/company/user`,
      userData
    )
  }

  deleteUser(userId: number) {
    return this.http.delete<any>(
      `${environment.server_url}/company/user/${userId}`
    )
  }

  reActivateUser(userId: number) {
    return this.http.put<any>(
      `${environment.server_url}/company/user/${userId}/reactivate`,
      {}
    )
  }
}
