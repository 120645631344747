import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable, BehaviorSubject } from 'rxjs'
import { environment } from '../../environments/environment'
import { IUserContactInformation, User } from '../models/user.model'
import { AuthenticationService } from './authentication.service'

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private currentUser: User

  public isReseller: BehaviorSubject<boolean>
  public accDeinst: BehaviorSubject<boolean>
  public accExhaustion: BehaviorSubject<boolean>
  public accFirstOwner: BehaviorSubject<boolean>
  constructor(
    private http: HttpClient,
    private authenticationService: AuthenticationService
  ) {
    this.isReseller = new BehaviorSubject(false)
    this.accDeinst = new BehaviorSubject(false)
    this.accExhaustion = new BehaviorSubject(false)
    this.accFirstOwner = new BehaviorSubject(false)

    this.authenticationService.userData.subscribe((data) => {
      this.currentUser = data
      this.isReseller.next(this.currentUser?.isReseller)
      this.accDeinst.next(this.currentUser?.accDeinst)
      this.accExhaustion.next(this.currentUser?.accExhaustion)
      this.accFirstOwner.next(this.currentUser?.accFirstOwner)
    })
  }

  getContactInformation(): Observable<IUserContactInformation[]> {
    return this.http.get<IUserContactInformation[]>(
      `${environment.server_url}/user/contact-information`
    )
  }

  updateContactInformation(contactData: IUserContactInformation) {
    return this.http.put<IUserContactInformation>(
      `${environment.server_url}/user/contact-information`,
      { contactData }
    )
  }

  updateEmail(emailData: any) {
    return this.http.put<any>(`${environment.server_url}/user/change-email`, {
      emailData,
    })
  }

  setSiteUpdateStatus(componentUpdate: string) {
    return this.http.put<any>(`${environment.server_url}/user/setSiteUpdates`, {
      componentUpdate,
    })
  }

  getSiteUpdateStatus() {
    return this.http.get<any>(`${environment.server_url}/user/getSiteUpdates`)
  }

  updatePassword(passwordData: any) {
    return this.http.put<any>(
      `${environment.server_url}/user/change-password`,
      { passwordData }
    )
  }

  signupNewsletter(email: string, language: string) {
    return this.http.put<any>(`${environment.server_url}/register/newsletter`, {
      email,
      language,
    })
  }
}
