import { Injectable } from '@angular/core'
import {
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router'
import { Location } from '@angular/common'
import { AuthenticationService } from '../services/authentication.service'
import { TranslateService } from '@ngx-translate/core'

@Injectable({ providedIn: 'root' })
export class AuthGuard {
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private translate: TranslateService,
    private location: Location
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentUser = this.authenticationService.currentUserValue
    if (currentUser) {
      // logged in so return true
      return true
    }

    // not logged in so redirect to login page with the return url and save the current url
    this.authenticationService.setLastPage(this.location.path())
    this.router.navigate([this.translate.currentLang + '/login'], {
      queryParams: { returnUrl: state.url },
    })
    return false
  }
}
