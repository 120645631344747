import { DOCUMENT, isPlatformBrowser } from '@angular/common'
import {
  Component,
  Inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
} from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { Angulartics2GoogleAnalytics } from 'angulartics2'
import {
  NgcCookieConsentService,
  NgcStatusChangeEvent,
} from 'ngx-cookieconsent'
import { Subscription } from 'rxjs'
import { landingPageJSONLD } from '../assets/schemas/landing-page'
import { environment } from '../environments/environment'
import { AuthenticationService } from './services/authentication.service'
import { CookieService } from './services/cookie.service'
import { SeoService } from './services/seo.service'
import { UserService } from './services/user.service'
import { CompanyService } from './services/company.service'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'li-x-frontend'
  language: string
  gaProperty = environment.gaID
  disableStr = `ga-disable-${this.gaProperty}`

  schema = {}
  loggedInUser: any
  userName: any
  name: any
  companyName: any

  // keep refs to subscriptions to be able to unsubscribe later
  private popupCloseSubscription: Subscription
  private statusChangeSubscription: Subscription
  private initializedSubscription: Subscription

  constructor(
    private angulartics2GoogleAnalytics: Angulartics2GoogleAnalytics,
    private cookieService: CookieService,
    private translateService: TranslateService,
    private ccService: NgcCookieConsentService,
    private seoService: SeoService,
    private userService: UserService,
    private authenticationService: AuthenticationService,
    private companyService: CompanyService,
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: any
  ) {
    this.language = this.translateService.currentLang
    this.document.documentElement.lang = this.language
    this.schema = landingPageJSONLD[this.language]
    this.seoService.getJsonLD().subscribe((json) => (this.schema = json))
    if (isPlatformBrowser(this.platformId)) {
      this.changeCookieConsentLang(this.language)
      this.translateService.onLangChange.subscribe((newTargetLang) => {
        this.changeCookieConsentLang(newTargetLang.lang)
      })
    }
  }

  changeCookieConsentLang(lang) {
    this.language = lang
    this.schema = landingPageJSONLD[this.language]
    this.document.documentElement.lang = this.language
    this.translateService //
      .get([
        'cookie.header',
        'cookie.message',
        'cookie.dismiss',
        'cookie.allow',
        'cookie.deny',
        'cookie.link',
        'cookie.policy',
      ])
      .subscribe((data) => {
        this.ccService.getConfig().content =
          this.ccService.getConfig().content || {}
        this.ccService.getConfig().content.header = data['cookie.header']
        this.ccService.getConfig().content.message = data['cookie.message']
        this.ccService.getConfig().content.dismiss = data['cookie.dismiss']
        this.ccService.getConfig().content.allow = data['cookie.allow']
        this.ccService.getConfig().content.deny = data['cookie.deny']
        this.ccService.getConfig().content.link = data['cookie.link']
        this.ccService.getConfig().content.policy = data['cookie.policy']

        this.ccService.destroy() // remove previous cookie bar (with default messages)
        this.ccService.init(this.ccService.getConfig()) // update config with translated messages
      })
  }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      this.angulartics2GoogleAnalytics.startTracking()
      this.authenticationService.currentUser.subscribe((userLoggedCheck) => {
        this.loggedInUser = userLoggedCheck
        if (this.loggedInUser !== null && this.loggedInUser !== undefined) {
          this.userService.getContactInformation().subscribe((userInfo) => {
            this.userName = { value: userInfo }
            this.name =
              this.userName.value.first_name +
              ' ' +
              this.userName.value.last_name
            this.companyService.getAddress().subscribe((companyDetails) => {
              this.companyName = companyDetails.name
              const body = <HTMLDivElement>document.body
              const script = document.createElement('script')
              script.innerText =
                `window._mfq.push(['setVariable', 'userName','${this.name}']);` +
                `window._mfq.push(['setVariable', 'companyName','${this.companyName}']);`
              body.appendChild(script)
            })
          })
        }
      })

      this.popupCloseSubscription = this.ccService.popupClose$.subscribe(
        () => {}
      )

      this.statusChangeSubscription = this.ccService.statusChange$.subscribe(
        (event: NgcStatusChangeEvent) => {
          // you can use this.ccService.getConfig() to do stuff...
          if (event.status === 'deny') {
            document.cookie =
              this.disableStr +
              '=true; expires=Thu, 31 Dec 2099 23:59:59 UTC; path=/'
            window[this.disableStr] = true
            this.cookieService.deleteCookie('_ga')
            this.cookieService.deleteCookie('_gaTracker')
            this.cookieService.deleteCookie('_gid')
            this.cookieService.deleteCookie('mf_user')
            this.cookieService.deleteCookie('Referer')
            // console.log(this.cookieService.getCookie('cookieconsent_status'))
          } else if (event.status === 'allow' || event.status === 'dismiss') {
            this.cookieService.deleteCookie(this.disableStr)
          }
          // refresh window to apply changes
          window.location.reload()
        }
      )

      this.initializedSubscription = this.ccService.initialized$.subscribe(
        () => {
          // the cookieconsent has been successfully initialized.
          if (this.ccService.hasAnswered()) {
            if (!this.ccService.hasConsented()) {
              this.cookieService.deleteCookie('Referer')
              window[this.disableStr] = true
            } else {
              this.cookieService.deleteCookie(this.disableStr)
            }
          }
        }
      )
    }
  }

  ngOnDestroy() {
    if (isPlatformBrowser(this.platformId)) {
      // unsubscribe to cookieconsent observables to prevent memory leaks
      this.popupCloseSubscription.unsubscribe()
      this.statusChangeSubscription.unsubscribe()
      this.initializedSubscription.unsubscribe()
    }
  }
}
