import { enableProdMode, isDevMode } from '@angular/core'
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic'
import { AppModule } from './app/app.module'
import { environment } from './environments/environment'
import { Workbox } from 'workbox-window'
import * as Sentry from '@sentry/angular-ivy'

Sentry.init({
  dsn: 'https://ed1fec83b80c2018ccf645f033ba36d0@o4507374350106624.ingest.de.sentry.io/4507374351941712',
  environment: environment.name || 'default',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost'],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

function loadServiceWorker() {
  if (environment.enableServiceWorker && 'serviceWorker' in navigator) {
    const wb = new Workbox('/sw.js')

    /*
     * The installed event is dispatched if the state of a Workbox instance's
     * registered service worker changes to installed.
     * Then can happen either the very first time a service worker is installed,
     * or after an update to the current service worker is found.
     * In the case of an update being found, the event's isUpdate property will be true.
     * Page reload is done to prevent chunk loading errors for lazy loaded modules.
     */
    wb.addEventListener('installed', (event) => {
      if (event.isUpdate) {
        if (environment.name.localeCompare('production') !== 0) {
          // eslint-disable-next-line no-console
          console.log('WB => New SW instance found. Reloading page...')
        }
        window.location.reload()
      } else {
        if (environment.name.localeCompare('production') !== 0) {
          // eslint-disable-next-line no-console
          console.log('WB => SW instance installed.')
        }
      }
    })

    wb.register()
  }
}

if (environment.production) {
  enableProdMode()
}

if (environment.enableServiceWorker && 'serviceWorker' in navigator) {
  navigator.serviceWorker.addEventListener('message', async (event) => {
    // Optional: ensure the message came from workbox-broadcast-update
    if (event.data.meta === 'workbox-broadcast-update') {
      const { cacheName, updatedURL } = event.data.payload

      // Do something with cacheName and updatedURL.
      // For example, get the cached content and update
      // the content on the page.
      const cache = await caches.open(cacheName)
      const updatedResponse = await cache.match(updatedURL)
      const updatedText = await updatedResponse.text()
      // eslint-disable-next-line no-console
      // console.log(JSON.parse(updatedText))
    }
  })
}

document.addEventListener('DOMContentLoaded', () => {
  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .then((_) => loadServiceWorker())
    .catch((err) => {
      if (isDevMode) {
        // eslint-disable-next-line no-console
        console.error(err)
      }
    })
})
