import {
  Router,
  ActivatedRoute,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router'
import { Injectable, Inject, PLATFORM_ID } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { isPlatformBrowser } from '@angular/common'

@Injectable()
export class LanguageResolver {
  constructor(
    private translate: TranslateService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    @Inject(PLATFORM_ID) private platformId: any
  ) {
    this.translate.addLangs(['en', 'de', 'fr', 'es', 'it'])
    this.translate.setDefaultLang('en')
  }

  async resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<any> {
    const languageList = this.translate.getLangs()
    const defaultLanguage = this.translate.getDefaultLang()
    const paramLanguage = route.paramMap.get('lang')
    let browserLanguage = this.translate.getDefaultLang()
    let storedLanguage = this.translate.getDefaultLang()
    let fullURL = state.url
    let language: string
    if (isPlatformBrowser(this.platformId)) {
      browserLanguage = this.translate.getBrowserLang()
      storedLanguage = localStorage.getItem('language')
    }
    if (fullURL === '/' || !languageList.includes(paramLanguage)) {
      if (fullURL === '/') {
        fullURL = ''
      }
      if (storedLanguage && languageList.includes(storedLanguage)) {
        language = storedLanguage
      } else {
        if (browserLanguage && languageList.includes(browserLanguage)) {
          language = browserLanguage
        } else {
          language = defaultLanguage
        }
      }
      await this.translate.use(language).toPromise()
      return this.router.navigateByUrl('/' + language + fullURL)
    } else {
      await this.translate.use(paramLanguage).toPromise()
    }
    return
  }
}
