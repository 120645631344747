import { Injectable, Inject, PLATFORM_ID } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { BehaviorSubject, Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { environment } from '../../environments/environment'
import { isPlatformServer } from '@angular/common'
import { JwtHelperService } from '@auth0/angular-jwt'
import { User } from '../models/user.model'

const helper = new JwtHelperService()

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<string>
  public userData: Observable<User>
  public currentUser: Observable<string>
  public isReseller: Observable<boolean>

  public redirectToLastPage = false
  private lastPage: string

  constructor(
    @Inject(PLATFORM_ID) private _platformId: Object,
    private http: HttpClient
  ) {
    this.currentUserSubject = new BehaviorSubject<string>(null)

    // Angular Universal server cannot read localStorage
    if (!isPlatformServer(this._platformId)) {
      this.currentUserSubject.next(JSON.parse(localStorage.getItem('JWT')))
      window.addEventListener('storage', this.storageEventListener.bind(this))
    }
    this.currentUser = this.currentUserSubject.asObservable()
    this.userData = this.currentUserSubject.pipe(
      map((token) => <User>helper.decodeToken(token))
    )
    this.isReseller = this.userData.pipe(
      map((user) => (user ? user.isReseller : false))
    )
  }

  public get currentUserValue(): string {
    return this.currentUserSubject.value
  }

  public get currentUserData(): User {
    let userData = new User()
    const token = this.currentUserSubject.value

    if (this.currentUserSubject.value) {
      userData = <User>helper.decodeToken(token)
    }

    return userData
  }

  login(username: string, password: string, acceptGTC?: boolean) {
    return this.http
      .post<any>(`${environment.server_url}/auth/login`, {
        email: username,
        password,
        acceptGTC,
      })
      .pipe(
        map((user) => {
          // login successful if there's a jwt token in the response
          this.processAuthResponse(user)
          return user
        })
      )
  }

  private processAuthResponse(user) {
    if (user && user.jwt && user.refresh_jwt) {
      const JWT = user['jwt']
      const REFRESH_JWT = user['refresh_jwt']
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      if (!isPlatformServer(this._platformId)) {
        localStorage.setItem('JWT', JSON.stringify(JWT))
        localStorage.setItem('REFRESH_JWT', JSON.stringify(REFRESH_JWT))
      }
      this.currentUserSubject.next(JWT)
    }
  }

  private storageEventListener(event: StorageEvent) {
    if (event.storageArea === localStorage) {
      if (event.key !== 'JWT') {
        return
      }
      let v: any
      try {
        v = JSON.parse(event.newValue)
      } catch (e) {
        v = event.newValue
      }
      this.currentUserSubject.next(v)
    }
  }

  public getLastPage() {
    return this.lastPage
  }

  public setLastPage(page: string) {
    this.lastPage = page
  }

  public refreshToken() {
    const REFRESH_TOKEN = JSON.parse(localStorage.getItem('REFRESH_JWT'))
    return this.http
      .post<any>(`${environment.server_url}/auth/refresh`, {
        refresh_token: REFRESH_TOKEN,
      })
      .pipe(
        map((resp) => {
          this.processAuthResponse(resp)
          return resp
        })
      )
  }

  logout() {
    // remove user from local storage to log user out
    if (!isPlatformServer(this._platformId)) {
      localStorage.removeItem('JWT')
      localStorage.removeItem('REFRESH_JWT')
    }
    this.currentUserSubject.next(null)
  }
}
