export const environment = {
  production: true,
  name: 'production',
  enableServiceWorker: true,
  server_url: 'https://www.li-x.com/api',
  pdfUrl: 'https://europe-west3-li-x-205007.cloudfunctions.net',
  storageUrl: 'https://storage.li-x.com',
  origin: 'https://www.li-x.com',
  environment: 'live',
  // originKey:
  //   'pub.v2.2615168847780128.aHR0cHM6Ly93d3cubGkteC5jb20.Hv4N3eoQuvOiWydHI1KhUcm2hENhuDMMtB38FdlWtD8',
  cookieURL: 'www.li-x.com',
  gaID: 'G-XVQZHTS4ZK',
  telemetrySiteId: '4',
}
