import { Injectable, Inject } from '@angular/core'
import { DOCUMENT } from '@angular/common'
import { environment } from '../../environments/environment'
import { IProductDetails } from '../models/product.model'
import { Meta, Title } from '@angular/platform-browser'
import { TranslateService } from '@ngx-translate/core'
import { PageMetaTags } from './prismic.service'
import { UtilsService } from './utils.service'
import { ActivatedRouteSnapshot } from '@angular/router'
import { Subject, ReplaySubject } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class SeoService {
  private finalUrl: string
  private jsonLD: Subject<any>
  private langURLs: ReplaySubject<any>
  private mainDomain: string

  constructor(
    @Inject(DOCUMENT) private doc,
    private meta: Meta,
    private title: Title,
    private translate: TranslateService,
    private utils: UtilsService
  ) {
    this.jsonLD = new Subject()
    this.langURLs = new ReplaySubject()
    this.mainDomain = 'li-x.com'
  }

  createHrefLinkTag(lang: any, urlSlug: any, defaultLang: boolean = false) {
    if (typeof lang === 'string' && typeof urlSlug === 'string') {
      this.finalUrl =
        environment.origin + '/' + lang + (urlSlug !== '' ? '/' + urlSlug : '')

      const link: HTMLLinkElement = this.doc.createElement('link')

      link.setAttribute('rel', 'alternate')

      if (defaultLang) {
        link.setAttribute('hreflang', 'x-default')
      } else {
        link.setAttribute('hreflang', lang)
      }
      link.setAttribute('href', this.finalUrl)
      this.doc.head.appendChild(link)
    }
  }

  removeHrefLinkTags() {
    const links = this.doc.head.querySelectorAll('link[rel=alternate]')
    Array.prototype.forEach.call(links, function (node) {
      node.parentNode.removeChild(node)
    })
    this.setlangURLs([])
    return
  }

  createCanonicalTag(fullUrlPath: string) {
    this.removeCanonicalTag()
    const link: HTMLLinkElement = this.doc.createElement('link')
    link.setAttribute('rel', 'canonical')
    link.setAttribute('href', fullUrlPath)
    this.doc.head.appendChild(link)
  }

  removeCanonicalTag() {
    const links = this.doc.head.querySelectorAll('link[rel=canonical]')
    Array.prototype.forEach.call(links, function (node) {
      node.parentNode.removeChild(node)
    })
    return
  }

  createDefaultProductMetaTags(product: IProductDetails) {
    let description = ''
    const robots = 'index,follow'

    if (product) {
      if (!this.meta.getTag('name=description')) {
        if (product.vendor) {
          description += `${product.vendor} `
        }
        if (product.name) {
          description += `${product.name}. `
        }
      }
      if (product.contains) {
        if (product.contains.length > 0) {
          let contains = `${this.translate.instant(
            'product.contained_products'
          )}: `
          contains += product.contains.map((e) => e.label).join(', ')
          description += `${contains}. `
        }
      }
      if (product.containedIn) {
        if (product.containedIn.length > 0) {
          let contains = `${this.translate.instant(
            'product.contained_in_bundles'
          )}: `
          contains += product.containedIn.map((e) => e.name).join(', ')
          description += `${contains}.`
        }
      }
      this.meta.addTag({ name: 'description', content: description })

      if (!this.meta.getTag('name=robots')) {
        this.meta.addTag({ name: 'robots', content: robots })
      }
    } else {
      this.removeOldMetaTags(['description', 'keywords', 'robots', 'googlebot'])
    }
  }

  setPublicPageMetaTags(data: PageMetaTags) {
    this.removeOldMetaTags(['description', 'keywords', 'robots', 'googlebot'])
    if (!data) {
      return
    }
    if (data.title) {
      this.title.setTitle(this.trimTitleToLength(data.title))
    } else {
      this.title.setTitle(
        this.trimTitleToLength(this.translate.instant('header.default_title'))
      )
    }
    Object.entries(data).map(([name, content]) => {
      if (name && content && name !== 'title' && name !== 'canonical') {
        if (this.meta.getTag(`name=${name}`)) {
          this.meta.updateTag(
            { name: name, content: content },
            `name='${name}'`
          )
        } else {
          this.meta.addTag({ name, content })
        }
      }
      return
    })
  }

  setMetaTagsFromRouteData(route: any) {
    this.removeOldMetaTags(['description', 'keywords', 'robots', 'googlebot'])
    if (route.data.meta) {
      // Title tag
      if (route.data.meta.title) {
        const title = this.translate.instant(route.data.meta.title)
        if (title) {
          this.title.setTitle(this.trimTitleToLength(title))
        }
      } else {
        const title = this.translate.instant('header.default_title')
        if (title) {
          this.title.setTitle(this.trimTitleToLength(title))
        }
      }
      // Description meta tag
      if (route.data.meta.description) {
        const description = this.translate.instant(route.data.meta.description)
        if (description) {
          this.meta.addTag({ name: 'description', content: description })
        }
      }
      // Robots meta tag
      if (route.data.meta.robots) {
        const robots = route.data.meta.robots
        if (robots) {
          this.meta.addTag({ name: 'robots', content: robots })
        }
      }
    }
  }

  public removeOldMetaTags(metaTags: Array<string>) {
    metaTags.forEach((mt) => {
      const found = this.meta.getTags(`name=${mt}`)
      found.forEach((fmt) => {
        this.meta.removeTagElement(fmt)
      })
    })
  }

  public getJsonLD() {
    return this.jsonLD
  }

  public setJsonLD(jsonld) {
    this.jsonLD.next(jsonld)
  }

  isCurrentURLPrincipalCanonical(
    route: ActivatedRouteSnapshot,
    canonicalURL: string
  ): boolean {
    let isCanonical = true
    if (canonicalURL) {
      const currentURL =
        environment.origin + '/' + this.utils.getFullResolvedUrl(route)
      canonicalURL =
        canonicalURL[canonicalURL.length - 1] !== '/'
          ? canonicalURL
          : canonicalURL.substr(0, canonicalURL.length - 1)
      isCanonical = currentURL.localeCompare(canonicalURL) === 0
    }
    return isCanonical
  }

  getCanonicalURLFromMeta(meta: any) {
    let canonicalURL = ''
    if (meta && meta.canonical) {
      canonicalURL = meta.canonical.startsWith('https')
        ? meta.canonical
        : environment.origin + meta.canonical
    }
    return canonicalURL
  }

  public getlangURLs() {
    return this.langURLs
  }

  public setlangURLs(langURLs) {
    this.langURLs.next(langURLs)
  }

  public insertATagRelAttribute(text: string, relValue: string) {
    let newATag: string
    const aTags = text.match(new RegExp('< *[a|A] +[^>]*', 'g')) || []
    aTags.map((aTag) => {
      if (aTag.includes(this.mainDomain)) {
        return
      }
      if (aTag.includes(relValue)) {
        return
      }
      if (aTag.includes('rel=')) {
        newATag = aTag.replace('rel="', 'rel="' + relValue + ' ')
      } else {
        newATag = aTag + ' rel="' + relValue + '"'
      }
      text = text.replace(aTag, newATag)
    })
    return text
  }

  /*
    This function cuts a string to a given length.
    It will try to only cut whole words.
   */
  public trimTitleToLength(str: string, maxLength: number = 60): string {
    if (typeof str !== 'string') {
      return str
    }

    if (
      typeof maxLength !== 'number' ||
      maxLength < 0 ||
      !Number.isInteger(maxLength)
    ) {
      return str
    }

    if (str.length <= maxLength) {
      return str
    }

    let suffix = ' | li-x'
    const hasSuffix = str.endsWith(suffix)

    if (hasSuffix) {
      maxLength = maxLength - suffix.length
      str = str.substring(0, str.length - suffix.length)
    } else {
      suffix = ''
    }

    const trimmedString = str.substring(0, maxLength)

    if (str[maxLength] === ' ' || str[maxLength] === undefined) {
      return `${trimmedString.trim()}${suffix}`
    }

    const lastSpaceIndex = trimmedString.lastIndexOf(' ')

    if (lastSpaceIndex === -1) {
      return `${trimmedString}${suffix}`
    }
    return `${trimmedString.substring(0, lastSpaceIndex).trim()}${suffix}`
  }
}
