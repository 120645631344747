import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { TranslateService } from '@ngx-translate/core'

@Component({
  selector: 'app-language',
  template: '<app-header fxLayout="column"></app-header>',
})
export class LanguageComponent implements OnInit {
  constructor(
    private translate: TranslateService,
    private activatedRoute: ActivatedRoute
  ) {}

  async ngOnInit() {
    this.activatedRoute.params.subscribe((param: any) => {
      this.translate.use(param['lang'])
    })
  }
}
