import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'

import { PriceConversionPipe } from './price-conversion.pipe'
import { OrderByDatePipe } from './order-by-date.pipe'
import { PurchaseFilterPipe } from './purchase-filter.pipe'
import { OrderByFieldPipe } from './order-by-field.pipe'

@NgModule({
  imports: [CommonModule],
  declarations: [
    PriceConversionPipe,
    OrderByDatePipe,
    PurchaseFilterPipe,
    OrderByFieldPipe,
  ],
  exports: [
    PriceConversionPipe,
    OrderByDatePipe,
    PurchaseFilterPipe,
    OrderByFieldPipe,
  ],
})
export class PipeModule {}
