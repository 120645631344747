<div fxLayout="row" fxLayoutGap="8.5%" class="locale-container">
  <div fxFlex="100px" fxLayout="column">
    <mat-list>
      <p class="locale-container__title">
        {{'header.title.select_language' | translate}}:
      </p>
      <div class="locale-container__items">
        <button
          id="englishLocaleID"
          mat-button
          [ngClass]="[language === 'en'?'active':'']"
          (click)="useLanguage('en')"
        >
          English
        </button>
        <button
          mat-button
          [ngClass]="[language === 'de'?'active':'']"
          (click)="useLanguage('de')"
        >
          Deutsch
        </button>
        <button
          mat-button
          [ngClass]="[language === 'fr'?'active':'']"
          (click)="useLanguage('fr')"
        >
          Français
        </button>
        <button
          mat-button
          [ngClass]="[language === 'es'?'active':'']"
          (click)="useLanguage('es')"
        >
          Español
        </button>
        <button
          mat-button
          [ngClass]="[language === 'it'?'active':'']"
          (click)="useLanguage('it')"
        >
          Italiano
        </button>
      </div>
    </mat-list>
  </div>
  <mat-divider [vertical]="true"></mat-divider>
  <div fxLayout="column">
    <mat-list>
      <p class="locale-container__title">
        {{'header.title.select_currency' | translate}}:
      </p>
      <div class="locale-container__items">
        <button
          mat-button
          [ngClass]="[currency==='EUR' ? 'active' : '']"
          (click)="useCurrency('EUR')"
        >
          EUR
        </button>
        <button
          mat-button
          [ngClass]="[currency==='GBP' ? 'active' : '']"
          (click)="useCurrency('GBP')"
        >
          GBP
        </button>
        <button
          mat-button
          [ngClass]="[currency==='CHF' ? 'active' : '']"
          (click)="useCurrency('CHF')"
        >
          CHF
        </button>
      </div>
    </mat-list>
  </div>
</div>
