import {
  Component,
  OnInit,
  HostBinding,
  HostListener,
  Input,
} from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { CurrencyService } from '../../services/currency.service'
import { Router } from '@angular/router'
import { DateAdapter } from '@angular/material/core'

@Component({
  selector: 'app-locale-menu',
  templateUrl: './locale-menu.component.html',
  styleUrls: ['./locale-menu.component.scss'],
})
export class LocaleMenuComponent implements OnInit {
  currency: string
  language: string
  constructor(
    private translateService: TranslateService,
    private currencyService: CurrencyService,
    private router: Router,
    private dateAdapter: DateAdapter<Date>
  ) {
    this.currency = this.currencyService.currentCurrency
    this.language = this.translateService.currentLang
  }

  ngOnInit() {
    this.dateAdapter.setLocale(this.translateService.getBrowserLang())
  }

  useCurrency(currency: string) {
    this.currency = currency
    this.currencyService.setCurrency(currency)
  }

  useLanguage(language: string) {
    this.language = language
    this.router.navigateByUrl(`/${language}${this.router.url.slice(3)}`)
    this.translateService.use(language)
    this.dateAdapter.setLocale(language)
  }
}
