import { CartItem } from '../models/cart.model'

import { Injectable, OnDestroy, PLATFORM_ID, Inject } from '@angular/core'
import { Observable, Subject } from 'rxjs'
import { share } from 'rxjs/operators'
import { isPlatformServer } from '@angular/common'

@Injectable({
  providedIn: 'root',
})
export class CartService implements OnDestroy {
  private onSubject = new Subject<CartItem[]>()
  public changes = share<CartItem[]>()(this.onSubject.asObservable())
  checkAllProducts: boolean

  constructor(@Inject(PLATFORM_ID) private _platformId: Object) {
    this.start()
  }

  ngOnDestroy() {
    this.stop()
  }

  public getCart() {
    if (!isPlatformServer(this._platformId)) {
      const cartContent = JSON.parse(localStorage.getItem('cart'))
      return Array.isArray(cartContent) ? cartContent : []
    }
    return []
  }

  public remove(id: number): number {
    const cart: CartItem[] = this.getCart()
    let affectedRows = 0
    cart.forEach((i: CartItem, index) => {
      if (i.id === id) {
        affectedRows += cart.splice(index, 1).length
      }
    })
    if (affectedRows) {
      if (!isPlatformServer(this._platformId)) {
        localStorage.setItem('cart', JSON.stringify(cart))
      }
      // the local application doesn't seem to catch changes to localStorage...
      this.onSubject.next(cart)
    }
    return affectedRows
  }

  public set(item: CartItem): void {
    const cart = this.getCart()
    let found = false
    cart.forEach((i: CartItem) => {
      if (i.id === item.id) {
        i.available = item.available
        i.quantity = item.quantity
        i.price = item.price
        i.discount = item.discount
        i.customPrice = item.customPrice
        found = true
      }
    })
    if (!found) {
      cart.push(item)
    }
    if (!isPlatformServer(this._platformId)) {
      localStorage.setItem('cart', JSON.stringify(cart))
    }
    // the local application doesn't seem to catch changes to localStorage...
    this.onSubject.next(cart)
  }

  public add(item: CartItem): void {
    const cart = this.getCart()
    let found = false
    cart.forEach((i: CartItem) => {
      if (i.id === item.id) {
        i.available = item.available
        i.quantity += item.quantity
        found = true
      }
    })
    if (!found) {
      cart.push(item)
    }
    // the if-statement is to prevent non-available items to be added to cart
    if (item.available.availability > 0) {
      if (!isPlatformServer(this._platformId)) {
        localStorage.setItem('cart', JSON.stringify(cart))
      }
    }
    // localStorage.setItem('cart', JSON.stringify(cart))
    this.onSubject.next(cart)
  }

  public clear() {
    if (!isPlatformServer(this._platformId)) {
      localStorage.removeItem('cart')
    }
    const cart: CartItem[] = this.getCart()
    this.onSubject.next(cart)
  }

  public checkAvailable() {
    this.checkAllProducts = true
    const productsCheck = []
    const cart = this.getCart()
    cart.forEach(function (product: CartItem) {
      productsCheck.push(
        product.quantity <= product.available.availability &&
          product.quantity <= product.available.limit
      )
    })
    this.checkAllProducts = productsCheck.includes(false)
    return this.checkAllProducts
  }

  private start(): void {
    if (!isPlatformServer(this._platformId)) {
      window.addEventListener('storage', this.storageEventListener.bind(this))
    }
  }

  private storageEventListener(event: StorageEvent) {
    if (event.storageArea === localStorage) {
      if (event.key !== 'cart') {
        return
      }
      let v: any
      try {
        v = JSON.parse(event.newValue)
      } catch (e) {
        v = event.newValue
      }
      this.onSubject.next(v)
    }
  }

  private stop(): void {
    if (!isPlatformServer(this._platformId)) {
      window.removeEventListener(
        'storage',
        this.storageEventListener.bind(this)
      )
      this.onSubject.complete()
    }
  }
}
