import { NgModule } from '@angular/core'
import { Routes, RouterModule } from '@angular/router'

import { AuthGuard } from './guards/auth.guard'
import { LanguageComponent } from './components/language/language.component'
import { SeoResolver } from './seo.resolver'
import { LanguageResolver } from './components/language/language.resolver'

const routes: Routes = [
  {
    path: ':lang',
    component: LanguageComponent,
    resolve: [LanguageResolver],
    children: [
      {
        path: 'login',
        loadChildren: () =>
          import('./components/login/login.module').then((m) => m.LoginModule),
        data: {
          meta: {
            title: 'login.meta.title',
          },
        },
        resolve: [SeoResolver],
      },
      {
        path: 'register',
        loadChildren: () =>
          import('./components/register/register.module').then(
            (m) => m.RegisterModule
          ),
        data: {
          meta: {
            title: 'register.meta.title',
          },
        },
        resolve: [SeoResolver],
      },
      {
        path: 'forgot',
        loadChildren: () =>
          import('./components/forgot-password/forgot-password.module').then(
            (m) => m.ForgotPasswordModule
          ),
        data: {
          meta: {
            title: 'forgot.meta.title',
            description: 'forgot.meta.description',
          },
        },
        resolve: [SeoResolver],
      },
      {
        path: 'buy',
        loadChildren: () =>
          import('./components/market/market.module').then(
            (m) => m.MarketModule
          ),
      },
      {
        path: 'cart',
        loadChildren: () =>
          import('./components/cart/cart.module').then((m) => m.CartModule),
      },
      {
        path: 'locale',
        loadChildren: () =>
          import('./components/locale-switcher/locale-switcher.module').then(
            (m) => m.LocaleSwitcherModule
          ),
      },
      {
        path: 'purchases',
        loadChildren: () =>
          import('./components/purchase/purchase.module').then(
            (m) => m.PurchaseModule
          ),
        canActivate: [AuthGuard],
        data: {
          meta: {
            title: 'purchase.meta.title',
          },
        },
        resolve: [SeoResolver],
      },
      {
        path: 'licences',
        data: {
          breadcrumb: 'Licences',
          meta: {
            title: 'licences.meta.title',
          },
        },
        resolve: [SeoResolver],
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./components/licence/licence.module').then(
            (m) => m.LicenceModule
          ),
      },
      {
        path: 'reseller',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./components/reseller/reseller.module').then(
            (m) => m.ResellerModule
          ),
        data: {
          meta: {
            title: 'reseller.meta.title',
          },
        },
        resolve: [SeoResolver],
      },
      {
        path: 'settings',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./components/setting/setting.module').then(
            (m) => m.SettingModule
          ),
      },
      {
        path: 'projects',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./components/project/project.module').then(
            (m) => m.ProjectModule
          ),
        data: {
          meta: {
            title: 'project.meta.title',
          },
        },
        resolve: [SeoResolver],
      },
      {
        path: 'checkout',
        canActivate: [AuthGuard],
        data: {
          breadcrumb: 'Billing Details',
          meta: {
            title: 'checkout.meta.title',
          },
        },
        loadChildren: () =>
          import('./components/checkout/checkout.module').then(
            (m) => m.CheckoutModule
          ),
        resolve: [SeoResolver],
      },
      {
        path: 'blog',
        loadChildren: () =>
          import('./components/blog/blog.module').then((m) => m.BlogModule),
      },
      {
        path: '',
        loadChildren: () =>
          import('./components/public/public.module').then(
            (m) => m.PublicModule
          ),
      },
    ],
  },
  {
    path: '',
    resolve: [LanguageResolver],
    component: LanguageComponent,
    pathMatch: 'full',
  },
]

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
      scrollOffset: [0, 150], // [x, y]
      initialNavigation: 'enabledBlocking',
      paramsInheritanceStrategy: 'always',
      // onSameUrlNavigation: 'reload',
    }),
  ],
  exports: [RouterModule],
  providers: [SeoResolver, LanguageResolver],
})
export class AppRoutingModule {}
