import { Injectable } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { MatSnackBar } from '@angular/material/snack-bar'

@Injectable({
  providedIn: 'root',
})
export class SnackbarService {
  constructor(
    private translate: TranslateService,
    private snackBar: MatSnackBar
  ) {}

  async showSnackbar(text: string, button: string, style?: string) {
    style = !style ? 'success-snack' : style
    const translations = await this.translate.get([text, button]).toPromise()
    return this.snackBar.open(translations[text], translations[button], {
      duration: 5000,
      panelClass: [style],
    })
  }
}
