import {
  Component,
  HostListener,
  OnInit,
  ViewChild,
  isDevMode,
} from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { Router } from '@angular/router'
import { LangChangeEvent, TranslateService } from '@ngx-translate/core'
import { Subject } from 'rxjs'
import { environment } from '../../../../environments/environment'
import { User } from '../../../models/user.model'
import { AuthenticationService } from '../../../services/authentication.service'
import { CartService } from '../../../services/cart.service'
import { CurrencyService } from '../../../services/currency.service'
import { LoaderService } from '../../../services/loader.service'
import { PrismicService } from '../../../services/prismic.service'
import { SeoService } from '../../../services/seo.service'
import { PopupComponent } from '../../popup/popup.component'
import { SearchComponent } from '../../search/search.component'

export interface MenuItem {
  title: string
  link: string
  params?: string
}

export interface SubMenu {
  title: string
  items: Array<any>
}

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @ViewChild(PopupComponent) popupMenu: PopupComponent
  currentUser: User

  topMenu: Array<MenuItem | SubMenu>
  localeCurrencyMenu: Array<any>

  mobileMenu: Array<any>

  loaderState: Subject<boolean>

  cartItems: number

  logoSuffix = environment.name === 'production' ? '' : '-' + environment.name

  currentPath: string

  seoPageUrl: string[] = []

  constructor(
    private router: Router,
    public currencyService: CurrencyService,
    private authenticationService: AuthenticationService,
    public loadingService: LoaderService,
    public cart: CartService,
    public dialog: MatDialog,
    public translate: TranslateService,
    private prismic: PrismicService,
    private seoService: SeoService
  ) {
    this.translate.onLangChange.subscribe(async (event: LangChangeEvent) => {
      this.loadMenu(event.lang)
    })
    this.cartItems = this.cart.getCart().length
    cart.changes.subscribe((content) => (this.cartItems = content.length))
    seoService.getlangURLs().subscribe((data) => {
      this.seoPageUrl = []
      data.forEach((url) => {
        this.seoPageUrl.push(
          url[url.length - 1] !== '/' ? url : url.substr(0, url.length - 1)
        )
      })
    })
  }

  loadMenu(lang?: string) {
    this.prismic
      .navigation(lang)
      .then((nav) => nav.data.mobile)
      .then((menuItems) => {
        const menu = []
        menuItems.forEach((item) => {
          switch (item.link.link_type) {
            case 'Document':
              menu.push({
                caption: item.caption,
                type: item.link.type,
                url: item.link.uid,
              })
              break
            case 'Web':
              menu.push({
                caption: item.caption,
                type: 'page',
                url: new URL(item.link.url).pathname,
              })
              break
            default:
              if (isDevMode) {
                // eslint-disable-next-line no-console
                console.error('unknown mobile menu entry type', item)
              }
          }
        })
        this.mobileMenu = menu
      })

    this.localeCurrencyMenu = [
      {
        title: this.translate.instant('header.lang'),
        items: [
          {
            title: 'English',
            code: 'en',
            isCurrency: false,
          },
          {
            title: 'Deutsch',
            code: 'de',
            isCurrency: false,
          },
          {
            title: 'Français',
            code: 'fr',
            isCurrency: false,
          },
          {
            title: 'Español',
            code: 'es',
            isCurrency: false,
          },
          {
            title: 'Italiano',
            code: 'it',
            isCurrency: false,
          },
        ],
      },
      {
        title: this.currencyService.currentCurrency,
        items: [
          {
            title: 'EUR',
            code: 'EUR',
            isCurrency: true,
          },
          {
            title: 'GBP',
            code: 'GBP',
            isCurrency: true,
          },
          {
            title: 'CHF',
            code: 'CHF',
            isCurrency: true,
          },
        ],
      },
    ]

    this.topMenu = [
      {
        title: this.translate.instant('header.title.used_software'),
        items: [
          {
            title: this.translate.instant('header.title.buy_licences'),
            link: this.translate.instant('header.link.buy_licences'),
          },
          {
            title: this.translate.instant('header.title.sell_licences'),
            link: this.translate.instant('header.link.sell_licences'),
          },
        ],
      },
      {
        title: this.translate.instant('header.title.our_products'),
        items: [
          {
            title: this.translate.instant('header.title.office_applications'),
            items: [
              {
                title: 'Office Pro Plus 2019',
                link: 'buy/1870/microsoft-office-professional-plus-2019',
              },
              {
                title: 'Office Standard 2019',
                link: 'buy/1871/microsoft-office-standard-2019',
              },
              {
                title: 'Office Pro Plus 2016',
                link: 'buy/1669/microsoft-office-professional-plus-2016',
              },
              {
                title: 'Office Standard 2016',
                link: 'buy/1676/microsoft-office-standard-2016',
              },
              {
                title: 'Project Professional 2016 w. CAL',
                link: 'buy/1686/microsoft-project-professional-2016-with-server-cal',
              },
              {
                title: 'Visio Professional 2016',
                link: 'buy/1687/microsoft-visio-professional-2016',
              },
              {
                title: 'Visio Standard 2016',
                link: 'buy/1740/microsoft-visio-standard-2016',
              },
              {
                title: 'Project Server 2016',
                link: 'buy/1801/microsoft-project-server-2016',
              },
              {
                title: this.translate.instant('header.title.all_applications'),
                link: 'buy',
                params: {
                  class: 'tools',
                },
              },
            ],
          },

          {
            title: this.translate.instant('header.title.server'),
            items: [
              {
                title: 'Windows Server Standard 2016',
                link: 'buy/1733/microsoft-windows-server-standard-2016-2core-8-licence-bundle',
              },
              {
                title: 'Exchange Server Standard 2016',
                link: 'buy/1660/microsoft-exchange-server-standard-2016',
              },
              {
                title: 'Exchange Server Enterprise 2016',
                link: 'buy/1744/microsoft-exchange-server-enterprise-2016',
              },
              {
                title: 'SQL Server Standard 2016',
                link: 'buy/1726/microsoft-sql-server-standard-2016',
              },
              {
                title: 'SQL Server Enterprise 2016',
                link: 'buy/1866/microsoft-sql-server-enterprise-2016',
              },
              {
                title: this.translate.instant('header.title.all_servers'),
                link: 'buy',
                params: {
                  class: 'server',
                },
              },
            ],
          },
          {
            title: this.translate.instant('header.title.operating_systems'),
            items: [
              {
                title: 'Windows 10 Enterprise LTSB 2016',
                link: 'buy/1877/microsoft-windows-10-enterprise-ltsb-2016-full-version-upgr-basis-bundle',
              },
              {
                title: 'Windows 10 Professional Upgrade',
                link: 'buy/1666/microsoft-windows-10-professional-upgrade',
              },
              {
                title: this.translate.instant(
                  'header.title.all_operating_systems'
                ),
                link: 'buy',
                params: {
                  class: 'os',
                },
              },
            ],
          },
          {
            title: this.translate.instant('header.title.more'),
            link: 'buy',
          },
        ],
      },
      {
        title: this.translate.instant('header.title.reseller'),
        link: this.translate.instant('header.link.reseller'),
      },
    ]
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(e) {
    if (window.pageYOffset) {
      const element = document.getElementById('toolbar')
      element.classList.add('shadow')
    } else {
      const element = document.getElementById('toolbar')
      element.classList.remove('shadow')
    }
  }

  ngOnInit() {
    this.authenticationService.userData.subscribe((x) => (this.currentUser = x))
    this.loaderState = this.loadingService.loadingEvent
    this.loadMenu(this.translate.currentLang)
  }

  search(e: MouseEvent) {
    e.stopPropagation()
    this.dialog.open(SearchComponent, {
      panelClass: 'search-dialog-container',
    })
  }

  goto(url: string) {
    this.router.navigate([this.translate.currentLang + '/' + url])
  }

  openPopup(e: MouseEvent, menuType: string, element) {
    this.popupMenu.open(e, menuType, element._elementRef)
  }
  getCurrentSlug() {
    this.currentPath = this.router.url
    const slug = this.currentPath.split('/')
    if (
      slug.includes('landing') ||
      slug.includes('purchase-licences') ||
      slug.includes('sell-licences') ||
      slug.includes('reseller-program')
    ) {
      this.authenticationService.redirectToLastPage = false
    } else {
      this.authenticationService.redirectToLastPage = true
    }
  }

  logout() {
    this.getCurrentSlug()
    // should get lang from browser
    this.authenticationService.logout()
    this.router.navigate([this.translate.currentLang, 'login'])
  }

  useCurrencyLanguage(item: any) {
    if (item.isCurrency) {
      this.currencyService.setCurrency(item.code)
      this.localeCurrencyMenu[1].title = item.code
    } else {
      // this.router.navigate(['/' + item.code + this.router.url.slice(3)])
      this.router.navigateByUrl(`/${item.code}${this.router.url.slice(3)}`)
    }
  }
}
