export class User {
  userId: number
  companyId: number
  isReseller: boolean
  verified: boolean
  permissions: string[]
  canTradeUnpaid: boolean
  accDeinst?: boolean
  accExhaustion?: boolean
  accFirstOwner?: boolean
}

export interface IUserContactInformation {
  id?: number
  first_name: string
  last_name: string
  position: string
  title: string
  language: string
}
