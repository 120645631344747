export const landingPageJSONLD = {
  es: {
    '@context': 'http://schema.org',
    '@type': 'Organization',
    url: 'https://www.li-x.com',
    sameAs: [
      'https://twitter.com/LicenceExchange?lang=en',
      'https://www.linkedin.com/company/li-x-gmbh/',
      'https://www.xing.com/companies/li-xgmbh',
    ],
    name: 'li-x GmbH',
    description: 'Software usado para empresas y revendedores',
    slogan: 'Asequible. Cumplido. Feliz.',
    logo: 'https://www.li-x.com/assets/img/li-x-logo.svg',
    image: 'https://www.li-x.com/assets/img/li-x-logo.svg',
    address: {
      '@type': 'PostalAddress',
      addressLocality: 'Hamburg, Germany',
      postalCode: '22303',
      streetAddress: 'Barmbeker Straße 2',
    },
    email: 'mailto:info@li-x.com',
    faxNumber: '+49-40609440911',
    founder: 'Boris Vöge',
    foundingDate: '2014',
    hasOfferCatalog: {
      '@type': 'OfferCatalog',
      name: 'Licencias de software usadas',
      url: 'https://www.li-x.com/es/buy',
    },
    contactPoint: [
      {
        '@type': 'ContactPoint',
        telephone: '+49-4060944090',
        contactType: 'customer service',
        areaServed: 'DE',
        availableLanguage: 'German',
        hoursAvailable: {
          '@type': 'OpeningHoursSpecification',
          opens: '09:00:00',
          closes: '17:00:00',
          dayOfWeek: 'Mo, Tu, Wed, Th, Fr',
        },
      },
      {
        '@type': 'ContactPoint',
        telephone: '+44-2036301339',
        contactType: 'customer service',
        areaServed: 'GB',
        availableLanguage: 'English',
        hoursAvailable: {
          '@type': 'OpeningHoursSpecification',
          opens: '09:00:00',
          closes: '17:00:00',
          dayOfWeek: 'Mo, Tu, Wed, Th, Fr',
        },
      },
      {
        '@type': 'ContactPoint',
        telephone: '+33-170383439',
        contactType: 'customer service',
        areaServed: 'FR',
        availableLanguage: 'French',
        hoursAvailable: {
          '@type': 'OpeningHoursSpecification',
          opens: '09:00:00',
          closes: '17:00:00',
          dayOfWeek: 'Mo, Tu, Wed, Th, Fr',
        },
      },
      {
        '@type': 'ContactPoint',
        telephone: '+34-935586515',
        contactType: 'customer service',
        areaServed: 'ES',
        availableLanguage: 'Spanish',
        hoursAvailable: {
          '@type': 'OpeningHoursSpecification',
          opens: '09:00:00',
          closes: '17:00:00',
          dayOfWeek: 'Mo, Tu, Wed, Th, Fr',
        },
      },
      {
        '@type': 'ContactPoint',
        telephone: '+39-3515401325',
        contactType: 'customer service',
        areaServed: 'IT',
        availableLanguage: 'Italian',
        hoursAvailable: {
          '@type': 'OpeningHoursSpecification',
          opens: '09:00:00',
          closes: '17:00:00',
          dayOfWeek: 'Mo, Tu, Wed, Th, Fr',
        },
      },
    ],
  },
  en: {
    '@context': 'http://schema.org',
    '@type': 'Organization',
    url: 'https://www.li-x.com',
    sameAs: [
      'https://twitter.com/LicenceExchange?lang=en',
      'https://www.linkedin.com/company/li-x-gmbh/',
      'https://www.xing.com/companies/li-xgmbh',
    ],
    name: 'li-x GmbH',
    description: 'Used Software for Reseller and Companies',
    slogan: 'Affordable. Compliant. Happy.',
    logo: 'https://www.li-x.com/assets/img/li-x-logo.svg',
    image: 'https://www.li-x.com/assets/img/li-x-logo.svg',
    address: {
      '@type': 'PostalAddress',
      addressLocality: 'Hamburg, Germany',
      postalCode: '22303',
      streetAddress: 'Barmbeker Straße 2',
    },
    email: 'mailto:info@li-x.com',
    faxNumber: '+49-40609440911',
    founder: 'Boris Vöge',
    foundingDate: '2014',
    hasOfferCatalog: {
      '@type': 'OfferCatalog',
      name: 'Used Software Licences',
      url: 'https://www.li-x.com/en/buy',
    },
    contactPoint: [
      {
        '@type': 'ContactPoint',
        telephone: '+49-4060944090',
        contactType: 'customer service',
        areaServed: 'DE',
        availableLanguage: 'German',
        hoursAvailable: {
          '@type': 'OpeningHoursSpecification',
          opens: '09:00:00',
          closes: '17:00:00',
          dayOfWeek: 'Mo, Tu, Wed, Th, Fr',
        },
      },
      {
        '@type': 'ContactPoint',
        telephone: '+44-2036301339',
        contactType: 'customer service',
        areaServed: 'GB',
        availableLanguage: 'English',
        hoursAvailable: {
          '@type': 'OpeningHoursSpecification',
          opens: '09:00:00',
          closes: '17:00:00',
          dayOfWeek: 'Mo, Tu, Wed, Th, Fr',
        },
      },
      {
        '@type': 'ContactPoint',
        telephone: '+33-170383439',
        contactType: 'customer service',
        areaServed: 'FR',
        availableLanguage: 'French',
        hoursAvailable: {
          '@type': 'OpeningHoursSpecification',
          opens: '09:00:00',
          closes: '17:00:00',
          dayOfWeek: 'Mo, Tu, Wed, Th, Fr',
        },
      },
      {
        '@type': 'ContactPoint',
        telephone: '+34-935586515',
        contactType: 'customer service',
        areaServed: 'ES',
        availableLanguage: 'Spanish',
        hoursAvailable: {
          '@type': 'OpeningHoursSpecification',
          opens: '09:00:00',
          closes: '17:00:00',
          dayOfWeek: 'Mo, Tu, Wed, Th, Fr',
        },
      },
      {
        '@type': 'ContactPoint',
        telephone: '+39-3515401325',
        contactType: 'customer service',
        areaServed: 'IT',
        availableLanguage: 'Italian',
        hoursAvailable: {
          '@type': 'OpeningHoursSpecification',
          opens: '09:00:00',
          closes: '17:00:00',
          dayOfWeek: 'Mo, Tu, Wed, Th, Fr',
        },
      },
    ],
  },
  de: {
    '@context': 'http://schema.org',
    '@type': 'Organization',
    url: 'https://www.li-x.com',
    sameAs: [
      'https://twitter.com/LicenceExchange?lang=en',
      'https://www.linkedin.com/company/li-x-gmbh/',
      'https://www.xing.com/companies/li-xgmbh',
    ],
    name: 'li-x GmbH',
    description: 'Gebrauchte Software für Unternehmen und Reseller',
    slogan: 'Günstig. Geprüft. Glücklich.',
    logo: 'https://www.li-x.com/assets/img/li-x-logo.svg',
    image: 'https://www.li-x.com/assets/img/li-x-logo.svg',
    address: {
      '@type': 'PostalAddress',
      addressLocality: 'Hamburg, Germany',
      postalCode: '22303',
      streetAddress: 'Barmbeker Straße 2',
    },
    email: 'mailto:info@li-x.com',
    faxNumber: '+49-40609440911',
    founder: 'Boris Vöge',
    foundingDate: '2014',
    hasOfferCatalog: {
      '@type': 'OfferCatalog',
      name: 'Gebrauchte Software-Lizenzen',
      url: 'https://www.li-x.com/de/buy',
    },
    contactPoint: [
      {
        '@type': 'ContactPoint',
        telephone: '+49-4060944090',
        contactType: 'customer service',
        areaServed: 'DE',
        availableLanguage: 'German',
        hoursAvailable: {
          '@type': 'OpeningHoursSpecification',
          opens: '09:00:00',
          closes: '17:00:00',
          dayOfWeek: 'Mo, Tu, Wed, Th, Fr',
        },
      },
      {
        '@type': 'ContactPoint',
        telephone: '+44-2036301339',
        contactType: 'customer service',
        areaServed: 'GB',
        availableLanguage: 'English',
        hoursAvailable: {
          '@type': 'OpeningHoursSpecification',
          opens: '09:00:00',
          closes: '17:00:00',
          dayOfWeek: 'Mo, Tu, Wed, Th, Fr',
        },
      },
      {
        '@type': 'ContactPoint',
        telephone: '+33-170383439',
        contactType: 'customer service',
        areaServed: 'FR',
        availableLanguage: 'French',
        hoursAvailable: {
          '@type': 'OpeningHoursSpecification',
          opens: '09:00:00',
          closes: '17:00:00',
          dayOfWeek: 'Mo, Tu, Wed, Th, Fr',
        },
      },
      {
        '@type': 'ContactPoint',
        telephone: '+34-935586515',
        contactType: 'customer service',
        areaServed: 'ES',
        availableLanguage: 'Spanish',
        hoursAvailable: {
          '@type': 'OpeningHoursSpecification',
          opens: '09:00:00',
          closes: '17:00:00',
          dayOfWeek: 'Mo, Tu, Wed, Th, Fr',
        },
      },
      {
        '@type': 'ContactPoint',
        telephone: '+39-3515401325',
        contactType: 'customer service',
        areaServed: 'IT',
        availableLanguage: 'Italian',
        hoursAvailable: {
          '@type': 'OpeningHoursSpecification',
          opens: '09:00:00',
          closes: '17:00:00',
          dayOfWeek: 'Mo, Tu, Wed, Th, Fr',
        },
      },
    ],
  },
  it: {
    '@context': 'http://schema.org',
    '@type': 'Organization',
    url: 'https://www.li-x.com',
    sameAs: [
      'https://twitter.com/LicenceExchange?lang=en',
      'https://www.linkedin.com/company/li-x-gmbh/',
      'https://www.xing.com/companies/li-xgmbh',
    ],
    name: 'li-x GmbH',
    description: 'Software usato per aziende e rivenditori',
    slogan: 'Conveniente. Conforme. Semplice.',
    logo: 'https://www.li-x.com/assets/img/li-x-logo.svg',
    image: 'https://www.li-x.com/assets/img/li-x-logo.svg',
    address: {
      '@type': 'PostalAddress',
      addressLocality: 'Hamburg, Germany',
      postalCode: '22303',
      streetAddress: 'Barmbeker Straße 2',
    },
    email: 'mailto:info@li-x.com',
    faxNumber: '+49-40609440911',
    founder: 'Boris Vöge',
    foundingDate: '2014',
    hasOfferCatalog: {
      '@type': 'OfferCatalog',
      name: 'Software usato',
      url: 'https://www.li-x.com/it/buy',
    },
    contactPoint: [
      {
        '@type': 'ContactPoint',
        telephone: '+49-4060944090',
        contactType: 'customer service',
        areaServed: 'DE',
        availableLanguage: 'German',
        hoursAvailable: {
          '@type': 'OpeningHoursSpecification',
          opens: '09:00:00',
          closes: '17:00:00',
          dayOfWeek: 'Mo, Tu, Wed, Th, Fr',
        },
      },
      {
        '@type': 'ContactPoint',
        telephone: '+44-2036301339',
        contactType: 'customer service',
        areaServed: 'GB',
        availableLanguage: 'English',
        hoursAvailable: {
          '@type': 'OpeningHoursSpecification',
          opens: '09:00:00',
          closes: '17:00:00',
          dayOfWeek: 'Mo, Tu, Wed, Th, Fr',
        },
      },
      {
        '@type': 'ContactPoint',
        telephone: '+33-170383439',
        contactType: 'customer service',
        areaServed: 'FR',
        availableLanguage: 'French',
        hoursAvailable: {
          '@type': 'OpeningHoursSpecification',
          opens: '09:00:00',
          closes: '17:00:00',
          dayOfWeek: 'Mo, Tu, Wed, Th, Fr',
        },
      },
      {
        '@type': 'ContactPoint',
        telephone: '+34-935586515',
        contactType: 'customer service',
        areaServed: 'ES',
        availableLanguage: 'Spanish',
        hoursAvailable: {
          '@type': 'OpeningHoursSpecification',
          opens: '09:00:00',
          closes: '17:00:00',
          dayOfWeek: 'Mo, Tu, Wed, Th, Fr',
        },
      },
      {
        '@type': 'ContactPoint',
        telephone: '+39-3515401325',
        contactType: 'customer service',
        areaServed: 'IT',
        availableLanguage: 'Italian',
        hoursAvailable: {
          '@type': 'OpeningHoursSpecification',
          opens: '09:00:00',
          closes: '17:00:00',
          dayOfWeek: 'Mo, Tu, Wed, Th, Fr',
        },
      },
    ],
  },
  fr: {
    '@context': 'http://schema.org',
    '@type': 'Organization',
    url: 'https://www.li-x.com',
    sameAs: [
      'https://twitter.com/LicenceExchange?lang=en',
      'https://www.linkedin.com/company/li-x-gmbh/',
      'https://www.xing.com/companies/li-xgmbh',
    ],
    name: 'li-x GmbH',
    description: 'Logiciels d occasion pour les entreprises et les revendeurs',
    slogan: 'Abordable. Conforme. Satisfait.',
    logo: 'https://www.li-x.com/assets/img/li-x-logo.svg',
    image: 'https://www.li-x.com/assets/img/li-x-logo.svg',
    address: {
      '@type': 'PostalAddress',
      addressLocality: 'Hamburg, Germany',
      postalCode: '22303',
      streetAddress: 'Barmbeker Straße 2',
    },
    email: 'mailto:info@li-x.com',
    faxNumber: '+49-40609440911',
    founder: 'Boris Vöge',
    foundingDate: '2014',
    hasOfferCatalog: {
      '@type': 'OfferCatalog',
      name: 'Logicielles d‘occasion',
      url: 'https://www.li-x.com/fr/buy',
    },
    contactPoint: [
      {
        '@type': 'ContactPoint',
        telephone: '+49-4060944090',
        contactType: 'customer service',
        areaServed: 'DE',
        availableLanguage: 'German',
        hoursAvailable: {
          '@type': 'OpeningHoursSpecification',
          opens: '09:00:00',
          closes: '17:00:00',
          dayOfWeek: 'Mo, Tu, Wed, Th, Fr',
        },
      },
      {
        '@type': 'ContactPoint',
        telephone: '+44-2036301339',
        contactType: 'customer service',
        areaServed: 'GB',
        availableLanguage: 'English',
        hoursAvailable: {
          '@type': 'OpeningHoursSpecification',
          opens: '09:00:00',
          closes: '17:00:00',
          dayOfWeek: 'Mo, Tu, Wed, Th, Fr',
        },
      },
      {
        '@type': 'ContactPoint',
        telephone: '+33-170383439',
        contactType: 'customer service',
        areaServed: 'FR',
        availableLanguage: 'French',
        hoursAvailable: {
          '@type': 'OpeningHoursSpecification',
          opens: '09:00:00',
          closes: '17:00:00',
          dayOfWeek: 'Mo, Tu, Wed, Th, Fr',
        },
      },
      {
        '@type': 'ContactPoint',
        telephone: '+34-935586515',
        contactType: 'customer service',
        areaServed: 'ES',
        availableLanguage: 'Spanish',
        hoursAvailable: {
          '@type': 'OpeningHoursSpecification',
          opens: '09:00:00',
          closes: '17:00:00',
          dayOfWeek: 'Mo, Tu, Wed, Th, Fr',
        },
      },
      {
        '@type': 'ContactPoint',
        telephone: '+39-3515401325',
        contactType: 'customer service',
        areaServed: 'IT',
        availableLanguage: 'Italian',
        hoursAvailable: {
          '@type': 'OpeningHoursSpecification',
          opens: '09:00:00',
          closes: '17:00:00',
          dayOfWeek: 'Mo, Tu, Wed, Th, Fr',
        },
      },
    ],
  },
}
