import { Injectable, PLATFORM_ID, Inject } from '@angular/core'
import { isPlatformServer } from '@angular/common'
import { Client } from '@prismicio/client'
import {
  LinkResolverFunction,
  asLink,
  Element,
  asHTML,
} from '@prismicio/helpers'
import { TranslateService } from '@ngx-translate/core'
import { PrismicDocument } from '@prismicio/types'
import { AsyncApiCallHelperService } from './async-api-call-helper.service'
import { firstValueFrom } from 'rxjs'

export type ContentBackground = 'transparent' | 'gray' | 'green' | 'blue'

const apiEndpoint = 'https://li-x-public.cdn.prismic.io/api/v2'

export interface Menu {
  title: string
  items: Array<MenuItem>
}

export interface Job {
  title: string
  description: string
}
export interface ProductDetails {
  description: string
  meta?: PageMetaTags
}

export interface ContentReference {
  logo: ContentImage
  text: string
  source: string
}

export interface ContentFeature {
  icon: string
  text: string
}

export interface ContentButton {
  label: string
  link: string
}

export interface ContentFaq {
  question: string
  answer: string
}

export interface ContentPressItem {
  ['press-image']: ContentImage
}

export interface ContentContactInfo {
  title: string
  content: string
}

export class ContentBlock {
  constructor(
    public type: string,
    public background = 'transparent'
  ) {}
}

export class ContentSell extends ContentBlock {
  constructor() {
    super('sell')
  }
}

export class ContentInquiry extends ContentBlock {
  constructor() {
    super('inquiry')
  }
}

export class ContentPress extends ContentBlock {
  constructor(
    public button1: ContentButton,
    public button2: ContentButton,
    public items: ContentPressItem[],
    background: ContentBackground
  ) {
    super('press', background)
  }
}

export class ContentNewsletter extends ContentBlock {
  constructor(
    public title: string,
    public subtitle: string,
    public disclaimer: string,
    public emailPlaceholder: string,
    public submitButtonLabel: string,
    background: ContentBackground
  ) {
    super('newsletter', background)
  }
}

export class ContentText extends ContentBlock {
  constructor(
    public text: string,
    background: ContentBackground
  ) {
    super('text', background)
  }
}

export class ContentSeperator extends ContentBlock {
  constructor(background: ContentBackground) {
    super('separator', background)
  }
}

export class ContentReferences extends ContentBlock {
  constructor(
    public title,
    public subtitle,
    public references: ContentReference[],
    background: ContentBackground
  ) {
    super('references', background)
  }
}

export class ContentFeatures extends ContentBlock {
  constructor(
    public features: ContentFeature[],
    background: ContentBackground
  ) {
    super('features', background)
  }
}

export class ContentJobsBlock extends ContentBlock {
  constructor(
    public text: string,
    public jobs: Job[]
  ) {
    super('jobs')
  }
}

export class ContentFaqBlock extends ContentBlock {
  constructor(
    public text: string,
    public questions: ContentFaq[]
  ) {
    super('faq')
  }
}

export class ContentImageTextBlock extends ContentBlock {
  constructor(
    public headline: string,
    public text: string,
    public image: ContentImage,
    public imageposition: string
  ) {
    super('imagetext')
  }
}

export class ContentTopProducts extends ContentBlock {
  constructor(
    public headline: string,
    public text: string,
    background: ContentBackground
  ) {
    super('top_products', background)
  }
}

export class ContentSplitTextImageBlock extends ContentBlock {
  constructor(
    public left_image: ContentImage,
    public left_text: string,
    public right_image: ContentImage,
    public right_text: string,
    background: ContentBackground
  ) {
    super('split_image_text', background)
  }
}

export class ContentTextBlock extends ContentBlock {
  constructor(
    public headline: string,
    public text: string,
    background: ContentBackground
  ) {
    super('textblock', background)
  }
}

export interface ContentImage {
  alt: string
  copyright: string
  url: string
  dimensions: {
    width: number
    height: number
  }
}

export interface PageMetaTags {
  [key: string]: string
}

export interface ContentPage {
  alternativeLanguages: Map<string, AlternativeLanguage>
  content: ContentBlock[]
  headline: string
  slug: string
  banner?: ContentImage
  bannerType?: string
  meta?: PageMetaTags
  language: string
}

export interface AlternativeLanguage {
  lang: string
  slug: string
}

export interface MenuItem {
  caption: string
  type: string
  url: string
}

export interface PostPreview {
  title: string
  slug: string
  date: string
  shorttext: string
  thumbnail: string
}

export interface Post {
  title: string
  slug: string
  date: string
  alternative_languages: Array<AlternativeLanguage>
  meta?: PageMetaTags
  text: string
  thumbnail: string
  banner: string
}

@Injectable({
  providedIn: 'root',
})
export class PrismicService {
  client: Client

  constructor(
    @Inject(PLATFORM_ID) private _platformId: any,
    private translate: TranslateService,
    private processor: AsyncApiCallHelperService
  ) {
    this.client = new Client(apiEndpoint)
  }

  public static fromPrismicLang(prismicLang: string) {
    switch (prismicLang) {
      case 'de-de':
        return 'de'
      case 'es-es':
        return 'es'
      case 'it-it':
        return 'it'
      case 'fr-fr':
        return 'fr'
      default:
        return 'en'
    }
  }

  public static toPrismicLang(lang: string) {
    switch (lang) {
      case 'de':
        return 'de-de'
      case 'es':
        return 'es-es'
      case 'it':
        return 'it-it'
      case 'fr':
        return 'fr-fr'
      default:
        return 'en-gb'
    }
  }

  static linkResolver: LinkResolverFunction<string> = (doc) => {
    const lang = '/' + PrismicService.fromPrismicLang(doc.lang) + '/'

    if (doc.type === 'post') {
      return lang + 'blog/' + doc.slug
    }

    if (doc.type === 'page') {
      return lang + doc.slug
    }

    if (doc.type === 'product') {
      return lang + 'buy/' + doc.uid + '/' + doc.slug
    }

    return null
  }

  async getFooter(lang: string) {
    const navigation = await this.navigation(lang)
    const menus = []
    if (navigation) {
      if (navigation.data.info_title[0]) {
        menus.push({
          title: navigation.data.info_title[0].text,
          items: navigation.data.info.map((item) => ({
            caption: item.caption,
            type: item.link.type,
            url: item.link.uid,
          })),
        })
      }
      if (navigation.data.legal_title[0]) {
        menus.push({
          title: navigation.data.legal_title[0].text,
          items: navigation.data.legal.map((item) => ({
            caption: item.caption,
            type: item.link.type,
            url: item.link.uid,
          })),
        })
      }
      if (navigation.data.contact[0]) {
        menus.push({
          title: navigation.data.contact[0].text,
          ...(navigation.data['contact-info'] && {
            content: this.RichTextToHtml(navigation.data['contact-info']),
          }),
        })
      }
    }
    return menus
  }

  async navigation(lang?: string) {
    return new Promise<PrismicDocument>((resolve) => {
      this.processor
        .doTask(this.client.getSingle('navigation', { lang: this.lang(lang) }))
        .subscribe((result) => {
          resolve(result)
        })
    })
  }

  async listBlog(lang?: string, limit = 50) {
    const posts = await this.client.getByType('post', {
      pageSize: limit,
      orderings: { field: 'my.post.date', direction: 'desc' },
      lang: this.lang(lang),
    })

    return {
      page: posts.page,
      results_per_page: posts.results_per_page,
      total_pages: posts.total_pages,
      posts: posts.results.map((post) => this.formatPostPreview(post)),
    }
  }

  async jobs(lang?: string): Promise<any[]> {
    return new Promise<any[]>((resolve) => {
      this.processor
        .doTask(
          this.client.getByType('job', {
            lang: this.lang(lang),
          })
        )
        .subscribe((jobs: any) => {
          resolve(!jobs ? [] : jobs.results.map(this.formatJob))
        })
    })
  }

  async product(
    softwareProductId: number,
    lang?: string
  ): Promise<ProductDetails> {
    const meta = {}
    const product = await new Promise<PrismicDocument>((resolve) => {
      this.processor
        .doTask(
          this.client.getByUID('product', softwareProductId.toString(), {
            lang: this.lang(lang),
          })
        )
        .subscribe((result) => {
          resolve(result)
        })
    })

    if (!product) {
      return
    }
    if (product.data.meta) {
      product.data.meta.forEach(({ key, value }) => {
        meta[key] = value
      })
    }
    return {
      description: this.RichTextToHtml(product.data.description),
      meta: meta,
    }
  }

  async slugsById(id: string) {
    const defaultPage = await new Promise<PrismicDocument>((resolve) => {
      this.processor.doTask(this.client.getByID(id)).subscribe((result) => {
        resolve(result)
      })
    })

    const slugs = {}
    slugs[PrismicService.fromPrismicLang(defaultPage.lang)] =
      defaultPage.slugs[0]
    defaultPage.alternate_languages.forEach((altLang: any) => {
      const { lang, uid } = altLang
      slugs[PrismicService.fromPrismicLang(lang)] = uid
    })
    return slugs
  }

  async page(slug: string, lang?: string): Promise<ContentPage> {
    const page = await firstValueFrom<PrismicDocument>(
      this.processor.doTask(
        this.client.getByUID('page', slug, {
          lang: this.lang(lang),
        })
      )
    )

    if (page === undefined) {
      return
    }
    const altLangs = new Map<string, AlternativeLanguage>()
    if (page.alternate_languages) {
      page.alternate_languages.forEach((altlang: any) => {
        altLangs.set(PrismicService.fromPrismicLang(altlang.lang), {
          lang: altlang.lang,
          slug: altlang.uid,
        })
      })
    }
    const content = []
    for (const slice of page.data.body) {
      try {
        switch (slice.slice_type) {
          case 'newsletter':
            content.push(
              new ContentNewsletter(
                this.RichTextToHtml(slice.primary['block-title']),
                this.RichTextToHtml(slice.primary.subtitle),
                this.RichTextToHtml(slice.primary.disclaimer),
                slice.primary['email-placeholder'],
                slice.primary['submit-button-label'],
                slice.primary.background
              )
            )
            continue
          case 'press':
            content.push(
              new ContentPress(
                {
                  label: slice.primary.label_button_1,
                  link: slice.primary.link_button_1,
                },
                {
                  label: slice.primary.label_button_2,
                  link: slice.primary.link_button_2,
                },
                slice.items,
                slice.primary.background
              )
            )
            continue
          case 'reference':
            content.push(
              new ContentReferences(
                this.RichTextToHtml(slice.primary.title1),
                this.RichTextToHtml(slice.primary.text),
                slice.items.map((item) => ({
                  logo: item.logo,
                  source: item.reference_source,
                  text: this.RichTextToHtml(item.reference_text),
                })),
                slice.primary.background
              )
            )
            continue
          case 'split_content':
            content.push(
              new ContentSplitTextImageBlock(
                slice.primary.left_image,
                this.RichTextToHtml(slice.primary.left_text),
                slice.primary.right_image,
                this.RichTextToHtml(slice.primary.right_text),
                slice.primary.background
              )
            )
            continue
          case 'faq':
            content.push(
              new ContentFaqBlock(
                this.RichTextToHtml(slice.primary.text),
                slice.items.map((item) => ({
                  question: item.question,
                  answer: this.RichTextToHtml(item.answer),
                }))
              )
            )
            continue
          case 'features':
            content.push(
              new ContentFeatures(
                slice.items.map((item) => ({
                  icon: item.icon,
                  text: this.RichTextToHtml(item.text),
                })),
                slice.primary.background
              )
            )
            continue
          case 'jobs':
            content.push(
              new ContentJobsBlock(
                this.RichTextToHtml(slice.primary['text']),
                await this.jobs(lang)
              )
            )
            continue
          case 'top_products':
            content.push(
              new ContentTopProducts(
                this.RichTextToHtml(slice.primary['block-title']),
                this.RichTextToHtml(slice.primary['text']),
                slice.primary.background
              )
            )
            continue
          case 'text':
            content.push(
              new ContentText(
                this.RichTextToHtml(slice.primary.text),
                slice.primary.background
              )
            )
            continue
          case 'separator':
            content.push(new ContentSeperator(slice.primary.background))
            continue

          case 'text_block':
            content.push(
              new ContentTextBlock(
                slice.primary['part-title'][0].text,
                this.RichTextToHtml(slice.primary['text']),
                slice.primary['background']
              )
            )
            continue
          case 'image_and_text':
            content.push(
              new ContentImageTextBlock(
                slice.primary['block-headline'][0]
                  ? slice.primary['block-headline'][0].text
                  : '',
                this.RichTextToHtml(slice.primary.text),
                slice.primary.image,
                slice.primary.imageposition
              )
            )
            continue
          case 'sell_form':
            content.push(new ContentSell())
            continue
          case 'inquiry_form':
            content.push(new ContentInquiry())
        }
      } catch (error) {
        if (isPlatformServer(this._platformId)) {
          // eslint-disable-next-line no-console
          console.log(
            'Error while proccessing Prismic response for public page: ',
            error
          )
        }
      }
    }
    const meta = {}
    meta['robots'] = 'index,follow'
    page.data.meta.forEach(({ key, value }) => {
      meta[key] = value
    })
    return {
      alternativeLanguages: altLangs,
      headline: page.data.headline[0].text,
      content: content,
      slug: page.uid,
      banner: page.data.banner,
      bannerType: page.data.bannertype,
      meta: meta,
      language: lang,
    }
  }

  formatJob(apiJob: PrismicDocument): Job {
    return {
      title: apiJob.data.title,
      description: this.RichTextToHtml(apiJob.data.description),
    }
  }

  formatPostPreview(apiPost: PrismicDocument): PostPreview {
    return {
      title: apiPost.data.title[0].text,
      slug: apiPost.uid,
      date: apiPost.data.date,
      shorttext: this.RichTextToHtml(apiPost.data.shorttext),
      thumbnail: apiPost.data.thumbnail,
    }
  }

  formatPost(apiPost: PrismicDocument): Post {
    const meta: PageMetaTags = {}
    meta['robots'] = 'index,follow'
    if (apiPost.data.shorttext[0] && apiPost.data.shorttext[0].text) {
      meta['description'] = apiPost.data.shorttext[0].text
    }
    if (apiPost.data.title[0] && apiPost.data.title[0].text) {
      meta['title'] = apiPost.data.title[0].text
    }
    if (apiPost.data.meta) {
      apiPost.data.meta.forEach(({ key, value }) => {
        meta[key] = value
      })
    }
    return {
      title: apiPost.data.title[0].text,
      text: this.RichTextToHtml(apiPost.data.text),
      date: apiPost.data.date,
      slug: apiPost.uid,
      alternative_languages: apiPost.alternate_languages.map(
        (altLang: any) => ({
          lang: PrismicService.fromPrismicLang(altLang.lang),
          slug: altLang.uid,
        })
      ),
      meta: meta,
      banner: apiPost.data.banner,
      thumbnail: apiPost.data.thumbnail,
    }
  }

  private RichTextToHtml(richText: any): any {
    return asHTML(richText, PrismicService.linkResolver, this.htmlSerializer)
  }

  htmlSerializer = (type, element, content, children) => {
    switch (type) {
      case Element.hyperlink:
        let target = ''
        if (
          element.data?.kind &&
          element.data.kind.toLocaleLowerCase() === 'document'
        ) {
          target = `target="_blank"`
        }
        let linkUrl = asLink(element.data, PrismicService.linkResolver)
        if (linkUrl) {
          linkUrl = linkUrl.replace(/^https:\/\/www.li-x.com\//, '/')
        }
        return `<a ${target} href="${linkUrl}">${Array.isArray(children) ? children.join('') : children}</a>`

      case Element.preformatted:
        return element ? element.text : null
      default:
        return null
    }
  }

  async blogpost(slug: string) {
    return new Promise<Post>((resolve) => {
      this.processor
        .doTask(this.client.getByUID('post', slug, { lang: this.lang() }))
        .subscribe((result) => {
          if (result) {
            resolve(this.formatPost(result))
          } else {
            // console.debug(`blog post ${slug} returned no result`)
            resolve(undefined)
          }
        })
    })
  }

  async get(slug: string) {
    return new Promise<PrismicDocument>((resolve) => {
      this.processor
        .doTask(this.client.getByUID('page', slug, { lang: this.lang() }))
        .subscribe((result) => {
          resolve(result)
        })
    })
  }

  private lang(lang?: string) {
    return PrismicService.toPrismicLang(
      lang ? lang : this.translate.currentLang
    )
  }
}
