import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { ContactMenuComponent } from './contact-menu.component'
import { TranslateModule } from '@ngx-translate/core'

@NgModule({
  imports: [CommonModule, TranslateModule],
  declarations: [ContactMenuComponent],
  exports: [ContactMenuComponent],
})
export class ContactMenuModule {}
