import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot } from '@angular/router'

@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  constructor() {}

  /**
   * It adds a new domain to the URL by replacing the existing one
   * or simply adding it if It doesn't exist.
   * @param url URL to be processed
   * @param newDomain New domain to be appended to the URL
   */
  public addDomain(url: string, newDomain: string) {
    return (
      newDomain +
      url.replace(new RegExp(/^(?:http:\/\/|www\.|https:\/\/)([^\/]+)/), '')
    )
  }

  public getFullResolvedUrl(route: ActivatedRouteSnapshot): string {
    return route.pathFromRoot
      .filter((e) => e.url && e.url.length > 0)
      .map((v) => v.url.map((segment) => segment.toString()).join('/'))
      .join('/')
  }

  private getNoLangResolvedUrl(route: ActivatedRouteSnapshot): string {
    return route.pathFromRoot
      .filter((e, index) => index > 1 && e.url && e.url.length > 0)
      .map((v) => v.url.map((segment) => segment.toString()).join('/'))
      .join('/')
  }

  public debounce(func: Function, wait: number) {
    let timeout

    return function executedFunction() {
      const context = this
      const args = arguments

      const later = function () {
        timeout = null
        func.apply(context, args)
      }

      clearTimeout(timeout)

      timeout = setTimeout(later, wait)
    }
  }
}
