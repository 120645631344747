import { AppRoutingModule } from './app-routing.module'
import { Router } from '@angular/router'
import { APP_ID, ErrorHandler, NgModule, APP_INITIALIZER } from '@angular/core'
import { AppComponent } from './app.component'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { FlexLayoutModule } from '@angular/flex-layout'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import packageInfo from '../../package.json'
import { provideClientHydration } from '@angular/platform-browser'
import { GlobalErrorHandler } from './helpers/global-error-handler'
import * as Sentry from '@sentry/angular-ivy'

// material components

// import ngx-translate and the http loader
import { TranslateLoader, TranslateModule } from '@ngx-translate/core'
import { TranslateHttpLoader } from '@ngx-translate/http-loader'
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from '@angular/common/http'

// locals and lang
import { registerLocaleData } from '@angular/common'
import localeFr from '@angular/common/locales/fr'
import localeFrExtra from '@angular/common/locales/extra/fr'
registerLocaleData(localeFr, 'fr', localeFrExtra)
import localeDe from '@angular/common/locales/de'
import localeDeExtra from '@angular/common/locales/extra/de'
registerLocaleData(localeDe, 'de', localeDeExtra)
import localeEn from '@angular/common/locales/en-GB'
import localeEnExtra from '@angular/common/locales/extra/en-GB'
registerLocaleData(localeEn, 'en', localeEnExtra)
import localeEs from '@angular/common/locales/es'
import localeEsExtra from '@angular/common/locales/extra/es'
registerLocaleData(localeEs, 'es', localeEsExtra)
import localeIt from '@angular/common/locales/it'
import localeItExtra from '@angular/common/locales/extra/it'
registerLocaleData(localeIt, 'it', localeItExtra)

// components & helpers
import { JwtInterceptor } from './helpers/jwt.interceptor'
import { ErrorInterceptor } from './helpers/error.interceptor'
import { FooterComponent } from './components/layout/footer/footer.component'
import { HeaderComponent } from './components/layout/header/header.component'
import { LocaleMenuComponent } from './components/locale-menu/locale-menu.component'
import { LanguageComponent } from './components/language/language.component'
import { SearchComponent } from './components/search/search.component'
import { PopupComponent } from './components/popup/popup.component'
import { MarketServiceFakeProvider } from './services/market.service.fake'
import { environment } from '../environments/environment'
import { LicenceServiceFakeProvider } from './services/licence.service.fake'
import { DialogGtcConfirmationComponent } from './components/dialog-gtc-confirmation/dialog-gtc-confirmation.component'
import { LoaderInterceptor } from './helpers/loader.interceptor'
import { ContactMenuModule } from './components/contact-menu/contact-menu.module'
import { MaterialModule } from './material/material.module'
import { MatExpansionModule } from '@angular/material/expansion'
import {
  NgcCookieConsentModule,
  NgcCookieConsentConfig,
} from 'ngx-cookieconsent'
import { Angulartics2Module } from 'angulartics2'
import { JsonldModule } from './components/jsonld/jsonld.module'
import { PageLoaderModule } from './components/layout/page-loader/page-loader.module'
import { DialogProjectWatchlistComponent } from './components/project/dialog-project-watchlist/dialog-project-watchlist.component'
import { provideMatomo, withRouter, withRouteData } from 'ngx-matomo-client'

const cookieConfig: NgcCookieConsentConfig = {
  cookie: {
    domain: environment.cookieURL,
  },
  palette: {
    popup: {
      background: '#edeff5',
      text: '#083EA7',
    },
    button: {
      background: '#083EA7',
    },
  },
  animateRevokable: true,
  theme: 'edgeless',
  revokable: false,
  type: 'opt-in',
  location: false,
  content: {
    message:
      'This website uses cookies to ensure you get the best experience on our website. You can read more about our "Privacy Policy"',
    dismiss: 'Allow cookies',
    // allow: 'Manage cookie choices',
    link: 'here',
    href: 'en/privacy-policy',
  },
}

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    HeaderComponent,
    LocaleMenuComponent,
    LanguageComponent,
    SearchComponent,
    PopupComponent,
    DialogGtcConfirmationComponent,
    DialogProjectWatchlistComponent,
  ],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    HttpClientModule,
    ContactMenuModule,
    MatExpansionModule,
    JsonldModule,
    NgcCookieConsentModule.forRoot(cookieConfig),
    Angulartics2Module.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    PageLoaderModule,
  ],
  exports: [MatExpansionModule],
  providers: [
    { provide: APP_ID, useValue: 'serverApp' },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    provideClientHydration(),
    ...(!environment.production
      ? [MarketServiceFakeProvider, LicenceServiceFakeProvider]
      : []),
    provideMatomo(
      {
        trackerUrl: `${environment.server_url}/telemetry/event`,
        trackerUrlSuffix: '',
        siteId: environment.telemetrySiteId || '2',
        scriptUrl: `${environment.server_url}/telemetry/telemetry.js`,
      },
      withRouter(),
      withRouteData()
    ),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(
    http,
    './assets/i18n/',
    '.json?version=' + packageInfo.version
  )
}
