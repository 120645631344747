import { Injectable } from '@angular/core'
import {
  HttpRequest,
  HttpResponse,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HTTP_INTERCEPTORS,
} from '@angular/common/http'
import { Observable, of, throwError } from 'rxjs'
import { delay, mergeMap, materialize, dematerialize } from 'rxjs/operators'

const licences = [
  {
    name: 'Arch Linux',
    quantity: 1,
    company: 'Freedom Software',
    price: 168,
    id: 1001,
  },
  {
    name: 'Fedora SELinux Foundation',
    quantity: 34,
    company: 'Redhat',
    price: 180,
    id: 1002,
  },
  {
    name: '<Product Name>',
    quantity: 34,
    company: 'Adobe',
    price: 180,
    id: 1003,
  },
  {
    name: '<Product Name>',
    quantity: 34,
    company: 'Adobe',
    price: 180,
    id: 1004,
  },
  {
    name: '<Product Name>',
    quantity: 34,
    company: 'Adobe',
    price: 180,
    id: 1005,
  },
  {
    name: '<Product Name>',
    quantity: 540,
    company: 'Adobe',
    price: 180,
    id: 1006,
  },
  {
    name: '<Product Name>',
    quantity: 3,
    company: 'Adobe',
    price: 168,
    id: 1007,
  },
  {
    name: '<Product Name>',
    quantity: 3,
    company: 'Adobe',
    price: 168,
    id: 1008,
  },
  {
    name: '<Product Name>',
    quantity: 3,
    company: 'Adobe',
    price: 168,
    id: 1009,
  },
  {
    name: '<Product Name>',
    quantity: 3,
    company: 'Adobe',
    price: 168,
    id: 1010,
  },
  {
    name: '<Product Name>',
    quantity: 1,
    company: 'Adobe',
    price: 168,
    id: 1011,
  },
]

const licenceDetails = [
  {
    id: 1001,
    name: 'Arch Linux',
    vendor: 'Freedom Software',
    class: 'OS',
    contracts: [
      {
        contract_number: 2140022,
        licence_agreement: 2140002,
        licence_number: 7579283,
        initial_owner: 'Bayerishcer Rundfunk Anstalt des offenlichen Rechts',
        quantity: 2,
        status: 2,
        language_support: 1,
        keys: [
          {
            quantity: 1,
            keys: [
              { type: 'windows', code: '1023-1003-2348-2394-1823-1823' },
              { type: 'mac', code: 'abc-def-asf' },
            ],
          },
          {
            quantity: 1,
            keys: [
              { type: '32bit', code: 'asdf-1003-2348-2394-1823-1823' },
              { type: 'mac', code: 'abc-def-asf' },
            ],
          },
        ],
      },
      {
        contract_number: 2140022,
        licence_agreement: 2140002,
        licence_number: 7579283,
        initial_owner: 'Bayerishcer Rundfunk Anstalt des offenlichen Rechts',
        quantity: 2,
        status: 2,
        language_support: 1,
        keys: [
          {
            quantity: 1,
            keys: [
              { type: 'windows', code: '1023-1003-2348-2394-1823-1823' },
              { type: 'mac', code: 'abc-def-asf' },
            ],
          },
          {
            quantity: 1,
            keys: [
              { type: '32bit', code: 'asdf-1003-2348-2394-1823-1823' },
              { type: 'mac', code: 'abc-def-asf' },
            ],
          },
        ],
      },
    ],
  },
  {
    id: 1002,
    name: 'Fedora SELinux Foundation',
    vendor: 'Redhat',
    class: 'OS',
    contracts: [
      {
        contract_number: 2140022,
        licence_agreement: 2140002,
        licence_number: 7579283,
        initial_owner: 'Bayerishcer Rundfunk Anstalt des offenlichen Rechts',
        quantity: 2,
        status: 2,
        language_support: 1,
        keys: [
          {
            quantity: 1,
            keys: [
              { type: 'windows', code: '1023-1003-2348-2394-1823-1823' },
              { type: 'mac', code: 'abc-def-asf' },
            ],
          },
          {
            quantity: 1,
            keys: [
              { type: '32bit', code: 'asdf-1003-2348-2394-1823-1823' },
              { type: 'mac', code: 'abc-def-asf' },
            ],
          },
        ],
      },
    ],
  },
]

@Injectable()
export class LicenceServiceFakeInterceptor implements HttpInterceptor {
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return of(null)
      .pipe(
        mergeMap(() => {
          // if (request.url.endsWith('/licences') && request.method === 'GET') {
          //   return ok(licences)
          // }
          // if (/\/licences\/details\/\d+$/i.test(request.url) && request.method === 'GET') {
          //   const id = parseInt(request.url.match(/\/licences\/details\/(\d+)$/i)[1], 10)
          //   for (const licence of licenceDetails) {
          //     if (licence.id === id) {
          //       return ok(licence)
          //     }
          //   }
          //   return error('licence details not found')
          // }
          // // pass through any requests not handled above
          return next.handle(request)
        })
      )
      .pipe(materialize())
      .pipe(delay(100))
      .pipe(dematerialize())

    // private helper functions

    function ok(body) {
      return of(new HttpResponse({ status: 200, body }))
    }

    function unauthorised() {
      return throwError({ status: 401, error: { message: 'Unauthorised' } })
    }

    function error(message) {
      return throwError({ status: 400, error: { message } })
    }
  }
}

export const LicenceServiceFakeProvider = {
  // use fake backend in place of Http service for backend-less development
  provide: HTTP_INTERCEPTORS,
  useClass: LicenceServiceFakeInterceptor,
  multi: true,
}
