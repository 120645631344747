import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import { environment } from '../../environments/environment'
import { IProductDetails, IProductContains } from '../models/product.model'

export interface IProductListing {
  id: number
  name: string
  price: number
  customPrice?: number
  priceText?: string
  vendor: string
  quantity?: number
  h: string
  discount?: number
  discountedPrice?: number
  discountedPriceText?: string
  vendor_price?: number
  available: number
  class: string
}

@Injectable({
  providedIn: 'root',
})
export class MarketService {
  constructor(private http: HttpClient) {}

  products(): Observable<IProductListing[]> {
    return this.http.get<IProductListing[]>(
      `${environment.server_url}/market/products`,
      {
        transferCache: false,
      }
    )
  }

  projectPrices(ids: string) {
    return this.http.get(`${environment.server_url}/market/prices?${ids}`)
  }

  // search function is not used anywhere in the project
  search(query: string): Observable<IProductListing[]> {
    return this.http.get<IProductListing[]>(
      `${environment.server_url}/market/search?q=${query}`
    )
  }

  product(
    id: number,
    bundleInfo = false,
    language = 'en'
  ): Observable<IProductDetails> {
    return this.http.get<IProductDetails>(
      `${environment.server_url}/market/product/${id}?lang=${language}` +
        (bundleInfo ? '&bundleInfo=1' : ''),
      {
        transferCache: false,
      }
    )
  }

  recommendations(id: number): Observable<IProductListing[]> {
    return this.http.get<IProductListing[]>(
      `${environment.server_url}/market/recommendations/${id}`
    )
  }

  // productContains function is not used anywhere in the project
  productContains(id: number): Observable<IProductContains> {
    return this.http.get<IProductContains>(
      `${environment.server_url}/market/product/${id}/contains`
    )
  }

  suggest(str: string) {
    return this.http.get<string[]>(
      `${environment.server_url}/market/suggest/${str}`
    )
  }

  getProductNotifications(): Observable<any[]> {
    return this.http.get<any[]>(
      `${environment.server_url}/market/notifications?notification_type=s`
    )
  }

  createProductNotification(data): Observable<any[]> {
    return this.http.post<any[]>(
      `${environment.server_url}/market/notification`,
      data
    )
  }

  updateProductNotification(productId, data): Observable<any[]> {
    return this.http.patch<any[]>(
      `${environment.server_url}/market/notification/${productId}`,
      data
    )
  }
}
