<form class="search-form">
  <input
    class="search-input"
    type="text"
    placeholder="{{'header.prod_search' | translate }}"
    matInput
    [formControl]="searchForm"
    (keydown)="searchKeyword($event, searchForm.value)"
    (keyup)="term$.next($event.target.value)"
    [matAutocomplete]="auto"
    aria-label="Number"
  />
  <mat-autocomplete #auto="matAutocomplete">
    <mat-option
      *ngFor="let option of filteredOptions | async"
      [value]="option"
      (click)="onClick(option)"
    >
      {{ option }}
    </mat-option>
  </mat-autocomplete>
</form>
