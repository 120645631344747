import { Injectable } from '@angular/core'

@Injectable({
  providedIn: 'root',
})
export class CookieService {
  constructor() {}

  public getCookie(name: string) {
    const cookieArray: Array<string> = document.cookie.split(';')
    const cookeiLength: number = cookieArray.length
    const cookieName = `${name}=`
    let cookie: string

    for (let i = 0; i < cookeiLength; i += 1) {
      cookie = cookieArray[i].replace(/^\s+/g, '')
      if (cookie.indexOf(cookieName) === 0) {
        return cookie.substring(cookieName.length, cookie.length)
      }
    }
    return ''
  }

  public deleteCookie(cookieName) {
    this.setCookie({ name: cookieName, value: '', expireDays: -1 })
  }

  public setCookie(params: any) {
    const date: Date = new Date()
    date.setTime(
      date.getTime() +
        (params.expireDays ? params.expireDays : 1) * 24 * 60 * 60 * 1000
    )
    document.cookie =
      (params.name ? params.name : '') +
      '=' +
      (params.value ? params.value : '') +
      ';' +
      (params.session && params.session === true
        ? ''
        : 'expires=' + date.toUTCString() + ';') +
      'path=' +
      (params.path && params.path.length > 0 ? params.path : '/') +
      ';' +
      (location.protocol === 'https:' && params.secure && params.secure === true
        ? 'secure'
        : '')
  }
}
